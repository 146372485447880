import type { Locale } from 'dayjs/locale/*'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'src/utils/dayjs'

type LocaleModule = {
  default: Locale
}
const localeData: Record<string, () => Promise<LocaleModule>> = {
  ja: () => import('dayjs/locale/ja'),
  it: () => import('dayjs/locale/it'),
  fr: () => import('dayjs/locale/fr'),
  es: () => import('dayjs/locale/es'),
  pt: () => import('dayjs/locale/pt'),
}

export const DayjsLocaleEffectApplier = () => {
  const { i18n } = useTranslation()
  const language = i18n.language

  useEffect(() => {
    if (language in localeData) {
      localeData[language]().then(({ default: data }) => dayjs.locale(data))
    } else {
      dayjs.locale('en')
    }
  }, [language])
  // TODO: Update locale of already-created dayjs instances

  return null
}
