import {
  cloneElement,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const allContainers = new Map<string, Context>()

export const dynamicCreateElement: CreateElement = (
  element: JSX.Element,
  name?: string,
) => {
  const c =
    name && allContainers.has(name)
      ? allContainers.get(name)
      : allContainers.values().next().value

  if (!c) {
    throw new Error(
      `dynamicCreateElement must be used with DynamicCreateElementContainer for now.`,
    )
  }

  return c.createElement(element, name)
}

export const useDynamicCreateElement = () => {
  const c = useContext(DynamicCreateElementContext)
  return c?.createElement || dynamicCreateElement
}

interface CreateElement {
  (element: JSX.Element, name?: string): () => void
}

interface Context {
  child: Set<Context>
  createElement?: CreateElement
}

const DynamicCreateElementContext = createContext<Context>({
  child: new Set(),
  createElement: undefined,
})

export const useForceUpdate = () => {
  const [, set] = useState(0)
  return useCallback(() => set(i => i + 1), [])
}

export const DynamicCreateElementContainer: FC<{
  name?: string
  children?: ReactNode
}> = ({ children, name = Math.random() + '' }) => {
  const forceUpdate = useForceUpdate()
  const elements = useMemo(() => new Set<JSX.Element>(), [])
  const parent = useContext(DynamicCreateElementContext)
  const child = useMemo(() => new Set<Context>(), [])
  const createElement = useCallback((args: JSX.Element, _name?: string) => {
    if (name !== _name && child.size) {
      const [c] = [...child.values()]
      return c?.createElement?.(args) || (() => {})
    }
    const element = cloneElement(args, {
      key: `dynamic-create-element-${Math.random()}`,
    })
    elements.add(element)
    forceUpdate()
    return () => {
      elements.delete(element)
      forceUpdate()
    }
  }, [])

  const value = useMemo<Context>(() => {
    return {
      child,
      createElement,
    }
  }, [])

  useEffect(() => {
    parent.child.add(value)
    allContainers.set(name, value)
    return () => {
      parent.child.delete(value)
      allContainers.delete(name)
    }
  }, [])

  return (
    <DynamicCreateElementContext.Provider value={value}>
      {children}
      {[...elements.values()]}
    </DynamicCreateElementContext.Provider>
  )
}
