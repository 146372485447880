import { IosShare } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FacebookIcon from '@mui/icons-material/Facebook'
import PinterestIcon from '@mui/icons-material/Pinterest'
import RedditIcon from '@mui/icons-material/Reddit'
import TwitterIcon from '@mui/icons-material/Twitter'
import {
  Button,
  ButtonBase,
  ButtonProps,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material'
import { FC, ReactNode, cloneElement, createElement as h, useRef } from 'react'
import { useMatches } from 'react-router-dom'
import { toast } from 'react-toastify'
import { OutboundLink } from 'src/components/OutboundLink'
import { AppRouteHandle } from 'src/router'
import { formatParamURL } from 'src/utils/qs'
import { track } from 'src/utils/tracker'

interface FormatShareURLOptions {
  link: string
  image?: string
  text?: string
  tags?: string[]
}

export const sharePlatformMap = {
  facebook: {
    color: '#1877f2',
    label: 'Facebook',
    icon: <FacebookIcon />,
    shareUrl: ({ link }: FormatShareURLOptions) =>
      formatParamURL('https://www.facebook.com/sharer/sharer.php', { u: link }),
  },
  pinterest: {
    color: '#e70022',
    label: 'Pinterest',
    icon: <PinterestIcon />,
    shareUrl: ({ link, image }: FormatShareURLOptions) =>
      formatParamURL('https://www.pinterest.com/pin/create/button/', {
        url: link,
        media: image,
        method: 'button',
      }),
  },
  twitter: {
    color: '#20a1f2',
    label: 'Twitter',
    icon: <TwitterIcon />,
    shareUrl: ({ link, text, tags }: FormatShareURLOptions) =>
      formatParamURL('https://twitter.com/intent/tweet', {
        url: link,
        text,
        hashtags: tags?.join(','),
      }),
  },
  reddit: {
    color: '#ff4500',
    label: 'Reddit',
    icon: <RedditIcon />,
    shareUrl: ({ link, text }: FormatShareURLOptions) =>
      formatParamURL('https://www.reddit.com/submit', {
        url: link,
        title: text,
      }),
  },
} as const

const sharePlatforms = Object.keys(
  sharePlatformMap,
) as (keyof typeof sharePlatformMap)[]

interface ShareButtonProps {
  type: keyof typeof sharePlatformMap
  link: string
  image?: string
  text?: string
  tags?: string[]
  buttonProps?: ButtonProps
  iconSize?: number
  render?: (props: {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    icon: ReactNode
    label: string
    color: string
  }) => ReactNode
  afterAction?: () => void
}

export const ShareButton: FC<ShareButtonProps> = ({
  type,
  link,
  image,
  text,
  tags,
  buttonProps,
  iconSize,
  render,
  afterAction,
}) => {
  const { color, label, icon, shareUrl } = sharePlatformMap[type]
  const socialUrl = shareUrl({
    link: formatParamURL(link, {
      utm_medium: 'referral',
      utm_source: type,
      utm_content: 'share',
    }),
    image,
    text,
    tags,
  })

  const route = useMatches().at(-1) as { handle: AppRouteHandle }

  const onClick = () => {
    track('share_social_btn_click', {
      type,
      scene: route.handle.name,
    })
  }

  const clonedIcon = cloneElement(icon, {
    style: {
      width: iconSize,
      height: iconSize,
    },
  })

  const inner = render ? (
    render({ onClick, icon: clonedIcon, label, color })
  ) : (
    <Button className="w-full py-2" onClick={onClick} {...buttonProps}>
      <div className="flex flex-col gap-1 items-center normal-case">
        <div
          className="h-12 w-12 flex items-center justify-center"
          style={{
            background: color,
            color: 'white',
            padding: '0.5rem',
            borderRadius: '50%',
          }}
        >
          {clonedIcon}
        </div>
        <div>{label}</div>
      </div>
    </Button>
  )

  return (
    <OutboundLink
      href={socialUrl}
      className="no-underline"
      onClick={afterAction}
    >
      {inner}
    </OutboundLink>
  )
}

export const copy = (
  content: string,
  {
    enableTrackValue,
    scene,
  }: { enableTrackValue?: boolean; scene?: string | null } = {},
) => {
  try {
    window.navigator.clipboard.writeText(content)
    toast.success('Copied to clipboard')
    if (scene != null)
      track('copy_success', {
        scene,
        ...(enableTrackValue ? { value: content } : {}),
      })
  } catch (err: any) {
    toast.warn(`Failed to copy to clipboard: ${err?.message}`)
    console.error(err)
    if (scene !== null) track('copy_failed', { scene })
  }
}

export const getArtworkShareLink = (id: string) =>
  `${location.origin}/artwork/${id}`
export const getModelShareLink = (id: string) =>
  `${location.origin}/model/${id}`

const SocialShare: FC<{
  link?: string
  image?: string
  text?: string
  tags?: string[]
  afterAction?: () => void
}> = ({ image, text, tags, afterAction, link }) => {
  if (!link) return null

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 flex-wrap">
        {sharePlatforms.map(type => {
          return h(ShareButton, {
            key: type,
            type,
            link,
            image,
            text,
            tags,
            afterAction,
          })
        })}
      </div>

      <ItemShareLink link={link} afterAction={afterAction} />
    </div>
  )
}

export const SystemShareButton: FC<{
  link: string
  text: string
  image?: string
  afterAction?: () => void
}> = ({ link, text, image, afterAction }) => {
  const route = useMatches().at(-1) as { handle: AppRouteHandle }

  const onClick: React.MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault()
    e.stopPropagation()

    const getImage = async () => {
      const blob = await fetch(image!).then(res => res.blob())
      return blob
    }

    if (navigator.share) {
      navigator
        .share({
          title: 'PixAI Art',
          text,
          url: link,
          ...(image
            ? { files: [new File([await getImage()], 'image.png')] }
            : {}),
        })
        .then(() => {
          track('share_system_btn_click', {
            type: 'system',
            scene: route.handle.name,
          })
        })
        .catch(err => {
          console.error(err)
        })
    } else {
      toast.warn('System share is not supported')
    }
    afterAction?.()
  }

  return (
    <IconButton
      className="border border-gray-300 border-solid"
      onClick={onClick}
    >
      <IosShare />
    </IconButton>
  )
}

export const ItemShareLink: FC<{
  className?: string
  link: string
  afterAction?: () => void
}> = ({ className, link, afterAction }) => {
  const route = useMatches().at(-1) as { handle: AppRouteHandle }
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <OutlinedInput
      className={className}
      value={link}
      readOnly
      size="small"
      inputProps={{
        style: {
          userSelect: 'all',
        },
      }}
      style={{
        padding: 0,
      }}
      inputRef={inputRef}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              track('share_copy_click', { scene: route.handle.name })
              copy(formatParamURL(link, { utm_source: 'copy_web' }))
              afterAction?.()
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </InputAdornment>
      }
      onMouseEnter={() => {
        inputRef.current?.select()
      }}
      onMouseLeave={() => {
        window.getSelection()?.removeAllRanges()
        inputRef.current?.blur()
      }}
    />
  )
}

export default SocialShare

export const ClickToCopy: FC<{
  content: string
  className?: string
  children?: ReactNode
}> = ({
  content,
  className,
  children = <ContentCopyIcon color="inherit" />,
}) => {
  return (
    <ButtonBase
      onClick={() => {
        copy(content)
      }}
      className={className}
    >
      {children}
    </ButtonBase>
  )
}
