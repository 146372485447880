import { ThemeProvider } from '@mui/material/styles'
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useLocalStorage, useMedia } from 'react-use'
import { darkTheme, lightTheme } from 'src/theme'
import { shouldNotHappen } from 'src/utils/error'

export type ColorModeValue = 'light' | 'dark'
type Mode = ColorModeValue | 'auto'

let colorModeValue: ColorModeValue
export const getColorModeValue = () => colorModeValue

const ColorModeContext = createContext<{
  mode?: Mode
  themeType: ColorModeValue
  changeColorMode: (mode: Mode) => void
}>({ mode: 'light', themeType: 'light', changeColorMode: () => {} })

export const useColorModeContext = () => useContext(ColorModeContext)

const ColorModeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [mode, setMode] = useLocalStorage<Mode>('app-color-theme', 'light')
  if (!mode) shouldNotHappen()

  const isPreferDark = useMedia('(prefers-color-scheme: dark)')

  const themeType = (colorModeValue = useMemo<ColorModeValue>(() => {
    return mode === 'auto' ? (isPreferDark ? 'dark' : 'light') : mode
  }, [mode, isPreferDark]))

  const theme = useMemo(() => {
    return themeType === 'dark' ? darkTheme : lightTheme
  }, [themeType])

  useEffect(() => {
    if (themeType === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [themeType])

  const value = useMemo(() => {
    return {
      mode,
      themeType,
      changeColorMode: setMode,
    }
  }, [mode, setMode, themeType])

  return (
    <ColorModeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default ColorModeProvider
