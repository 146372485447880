import { useEffect } from 'react'
import { mutateUserInfo } from 'src/services'
import { client } from 'src/services/client'
import { getApiUrl } from './config'
import { useQs } from './qs'
import { getToken, setToken } from './token'
import { track } from './tracker'

export const getCurrentUrlWithoutQuery = () =>
  window.location.href.split('?').shift()

export const addRedirectState = (
  url: string,
  target = window.location.href,
) => {
  const state = encodeURIComponent(`redirect=${target}`)
  const u = new URL(url)
  u.searchParams.append('state', state)
  u.searchParams.append('token', getToken())
  return u.toString()
}

export const redirectToOAuth = (type: string, target?: string) => {
  const url = addRedirectState(`${getApiUrl()}/auth/${type}`, target)
  window.location.href = url
}

export const useCommonTokenHandler = () => {
  const [{ token, isNew }, setQs] = useQs()

  useEffect(() => {
    if (token) {
      setToken(token)
      track(isNew === 'true' ? 'register_success' : 'login_success', {
        type: 'token',
      })

      setQs({ token: null, isNew: null })
      mutateUserInfo()
    }
  }, [token])

  useEffect(() => {
    if (getToken()) client.refreshToken({})
  }, [])
}
