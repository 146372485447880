import {
  Breakpoint,
  Dialog,
  DialogProps,
  ModalProps,
  SwipeableDrawer,
} from '@mui/material'
import { FC, ReactNode, Suspense } from 'react'
import { useIsMobile } from 'src/utils/hooks/useIsMobile'
import { cx } from '../../utils/cx'
import LoadingPage from '../LoadingPage'

const ResponsiveDialog: FC<{
  open: boolean
  onClose: (...args: any) => void
  children?: ReactNode
  dialogProps?: Partial<DialogProps>
  className?: string
  breakpoint?: Breakpoint
  fullHeight?: boolean
  /**
   * @default true
   * Only applies to SwipeableDrawer
   */
  disableScrollLock?: ModalProps['disableScrollLock']
}> = ({
  open,
  onClose = () => {},
  children,
  dialogProps,
  className,
  breakpoint,
  fullHeight = true,
  disableScrollLock = true,
}) => {
  const isMobile = useIsMobile(breakpoint)

  // for unknown reason drawer persistent keep mounted children, force unmount children on close
  const content = open && (
    <Suspense fallback={<LoadingPage />}>{children}</Suspense>
  )

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      disableScrollLock={disableScrollLock}
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      disableDiscovery
      sx={{
        '& .MuiDrawer-paper': {
          height: fullHeight ? 'calc(100% - 4rem)' : 'auto',
        },
      }}
      PaperProps={{
        sx: {
          '&': {
            borderRadius: '28px 28px 0 0',
          },
        },
      }}
    >
      <div className="relative h-full overflow-hidden">
        {/* handle */}
        <div
          className="mx-auto w-full h-[24px] flex justify-center items-center"
          onClick={onClose}
        >
          <div
            className="w-12 h-[5px] bg-gray-300 rounded-full"
            style={{ opacity: 1 }}
          >
            &nbsp;
          </div>
        </div>

        <div
          className={cx(
            'overflow-auto h-[calc(100%-24px)] relative',
            className,
          )}
        >
          {/* 21px = 5px (height of handle) + 2 * 8px (margin) */}
          {content}
        </div>
      </div>
    </SwipeableDrawer>
  ) : (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      PaperProps={{
        ...dialogProps?.PaperProps,
        sx: {
          ...dialogProps?.PaperProps?.sx,
          '&.MuiPaper-rounded': {
            borderRadius: '24px',
          },
        },
        className: cx(dialogProps?.className, className),
      }}
      {...dialogProps}
    >
      {content}
    </Dialog>
  )
}

export default ResponsiveDialog
