import { Autocomplete, Button, TextField } from '@mui/material'
import { compact, uniq } from 'lodash-es'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { config } from 'src/utils/config'
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage'

const ApiBaseUrlSelect = () => {
  const [apiBaseUrl, setApiBaseUrl] = useLocalStorage(
    'api-base-url',
    config.apiUrl,
  )
  const [apiBaseUrlOptions, setApiBaseUrlOptions] = useLocalStorage<string[]>(
    'api-base-url-hist',
    [],
  )
  const [apiBaseUrlValue, setApiBaseUrlValue] = useState(apiBaseUrl)

  const submitApiBaseUrl = () => {
    setApiBaseUrl(apiBaseUrlValue)
    setApiBaseUrlOptions(values => {
      return uniq([apiBaseUrlValue, ...values]).slice(0, 10)
    })
    toast.success(`Changed to ${apiBaseUrlValue}. Refresh to take effect.`)
  }

  return (
    <div className="w-full flex gap-4">
      <Autocomplete
        freeSolo
        options={compact(
          uniq([
            'https://api.pixai.art',
            'https://papi-main.ring.mewtant.co',
            'http://127.0.0.1:3011',
            'http://dev.local:3011',
            config.apiUrl,
            ...apiBaseUrlOptions,
          ]),
        )}
        className="flex-1"
        size="small"
        renderInput={params => {
          return <TextField label="API Base URL" {...params} />
        }}
        placeholder={config.apiUrl}
        value={apiBaseUrlValue}
        onInputChange={(_, v) => {
          setApiBaseUrlValue(v ?? '')
        }}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            submitApiBaseUrl()
          }
        }}
      />
      <Button
        variant="contained"
        onClick={() => {
          submitApiBaseUrl()
        }}
      >
        save
      </Button>
    </div>
  )
}

export default ApiBaseUrlSelect
