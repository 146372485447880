import { Media, MediaType, TaskBaseFragment } from 'src/services/generated'
import { isAnimatedImageTask } from './task'

export const getPublicUrl = (media?: Media) =>
  media?.urls?.find(url => url.variant === 'PUBLIC')?.url

export const getThumbnailUrl = (media?: Media) =>
  media?.urls?.find(url => url.variant === 'THUMBNAIL')?.url

export const getStillThumbnailUrl = (media?: Media) =>
  media?.urls?.find(url => url.variant === 'STILL_THUMBNAIL')?.url

/**
 * Get the video url for a media object
 *
 * Note: Only video media objects have this
 */
export const getVideoUrl = (media?: Media) => media?.fileUrl

export const getVideoMediaIdFromTask = (
  task?: TaskBaseFragment,
): string | undefined =>
  // Only animated tasks have video
  isAnimatedImageTask(task) ? task?.outputs?.videos?.[0]?.mediaId : undefined

export const isVideoMedia = (media?: Media) => media?.type === MediaType.Video

export const getSmartThumbnailUrl = (media?: Media, preferStill?: boolean) => {
  if (!media) return undefined
  const publicUrl = getPublicUrl(media)
  const thumbnailUrl = getThumbnailUrl(media)
  // replace 'public' with resizing option
  if (publicUrl?.includes('public')) {
    const { width } = media
    const variant = !width || width > 1000 ? 'thumbnail' : `w=${width - 1}`
    return publicUrl.replace('public', variant)
  }
  // nothing to replace
  return (preferStill ? getStillThumbnailUrl(media) : null) || thumbnailUrl
}

export const getAspectRatio = (media?: Pick<Media, 'height' | 'width'>) =>
  media?.width && media?.height ? media.width / media.height : 1

export const downloadMedia = async (media?: Media) => {
  if (!media) return
  const url = getPublicUrl(media)
  if (!url) return
  const res = await fetch(url)
  const blob = await res.blob()
  const file = new File([blob], media.id, {
    type: media.imageType,
  })
  return file
}

export function shouldMediaBeBlurred(media?: Media) {
  return media?.flag?.shouldBlur ?? false
}
