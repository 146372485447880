import { TFunction } from 'i18next'
import { kebabCase } from 'lodash-es'

export function shouldNotHappen(): never {
  throw new Error('This should not happen')
}

export function missingArgumentError(name: string): never {
  throw new Error(`Missing required argument: ${name}`)
}

export function getErrorMessage(
  t: TFunction,
  args: {
    errName: string
    /**
     * `artwork:` | `market:model.` | `manga:`
     */
    i18nKeyPrefix: string
  },
) {
  const { errName, i18nKeyPrefix } = args
  return t(`${i18nKeyPrefix}errors.${kebabCase(errName).toLowerCase()}`)
}
