export function MembershipCrownGradient() {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className="invisible w-0 h-0"
    >
      <defs>
        <linearGradient id="member-crown-gradient" x1="1" x2="0" y1="0" y2="1">
          <stop offset="0%" stopColor="hsl(165deg 100% 44%)" />
          <stop offset="20%" stopColor="hsl(172deg 100% 42%)" />
          <stop offset="29%" stopColor="hsl(181deg 100% 42%)" />
          <stop offset="36%" stopColor="hsl(190deg 100% 47%)" />
          <stop offset="43%" stopColor="hsl(195deg 100% 50%)" />
          <stop offset="50%" stopColor="hsl(197deg 100% 50%)" />
          <stop offset="57%" stopColor="hsl(201deg 100% 50%)" />
          <stop offset="64%" stopColor="hsl(205deg 100% 50%)" />
          <stop offset="71%" stopColor="hsl(210deg 100% 50%)" />
          <stop offset="80%" stopColor="hsl(240deg 100% 68%)" />
          <stop offset="100%" stopColor="hsl(281deg 99% 53%)" />
        </linearGradient>
      </defs>
    </svg>
  )
}
