import { FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import ResponsiveDialog from 'src/components/Common/ResponsiveDialog'
import { useUserInfo } from 'src/services'
import { config, getApiEnv, getApiUrl, getWebsocketUrl } from 'src/utils/config'
import { cx } from 'src/utils/cx'
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage'
import MdiRefresh from '~icons/mdi/refresh'
import DebugSettings from './DebugSettings'

const DebugWidget = () => {
  const { data: userInfo } = useUserInfo()
  const [enableDebug, setEnableDebug] = useLocalStorage<boolean>('enable-debug')
  useLocalStorage('api-base-url', config.apiUrl)

  const isProdHost = window.location.hostname === 'pixai.art'
  const apiEnv = getApiEnv()

  useEffect(() => {
    if ((!isProdHost || userInfo?.isAdmin) && enableDebug == null) {
      setEnableDebug(true)
    }
  }, [userInfo])

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const indicator = enableDebug ? (
    <Tooltip
      title={
        <div>
          <div className="font-bold">click to show debug widget</div>
          <div>
            API URL: {getApiUrl()} <br />
            WebSocket URL: {getWebsocketUrl()}
          </div>
          {config.commitHash && (
            <div>commit: {config.commitHash?.slice(0, 8)}</div>
          )}
          {userInfo?.isAdmin && (
            <div className="text-yellow-500">
              (You are admin. Operate with caution.)
            </div>
          )}
        </div>
      }
    >
      <button
        className={cx(
          'fixed text-sm bottom-4 left-4 z-20 flex gap-1 opacity-70',
        )}
        onClick={() => setIsDialogOpen(true)}
      >
        <span>+ app:</span>
        <span className={config.isProd ? 'text-red-500' : 'text-yellow-500'}>
          {config.isProd ? (isProdHost ? 'production' : 'preview') : 'local'}
        </span>
        <span>api:</span>
        <span className={apiEnv.isProd ? 'text-red-500' : 'text-yellow-500'}>
          {apiEnv.name}
        </span>
      </button>
    </Tooltip>
  ) : null

  return (
    <>
      {indicator}
      <ResponsiveDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        dialogProps={{ maxWidth: 'sm' }}
      >
        <div className="p-8 flex flex-col gap-8 font-inter">
          <h2 className="flex items-center text-2xl font-bold">
            <span>Debug Settings</span>
            <FormControlLabel
              label={'Env Indicator'}
              labelPlacement="start"
              control={
                <Switch
                  checked={enableDebug}
                  onChange={(_, value) => {
                    setEnableDebug(value)
                  }}
                />
              }
              className="ml-auto"
            />
            <IconButton className="ml-4" onClick={() => location.reload()}>
              <MdiRefresh />
            </IconButton>
          </h2>
          <DebugSettings />
        </div>
      </ResponsiveDialog>
    </>
  )
}

export default DebugWidget
