export const setVisibleDimensions = () => {
  if (typeof window === 'undefined') return
  const heightVarName = 'visible-height'
  const widthVarName = 'visible-width'
  const set = () => {
    document.documentElement.style.setProperty(
      '--' + heightVarName,
      window.innerHeight + 'px',
    )
    document.documentElement.style.setProperty(
      '--' + widthVarName,
      window.innerWidth + 'px',
    )
  }
  set()
  window.addEventListener('resize', set)
}
