import {
  GenerationModel,
  GenerationModelType,
  ModerationStatus,
} from 'src/services/generated'
import { P, isMatching } from 'ts-pattern'

export const isSDModel = isMatching(
  // prettier-ignore
  P.union(
    GenerationModelType.SdV1Model,
    GenerationModelType.SdxlModel,
    GenerationModelType.UnknownModel,
  ),
)

export const isXLModel = isMatching(GenerationModelType.SdxlModel)

export const isLora = isMatching(
  P.union(
    GenerationModelType.Lora,
    GenerationModelType.SdxlLora,
    GenerationModelType.UserLora,
    GenerationModelType.UserSdxlLora,
    GenerationModelType.UnknownLora,
  ),
)

export const isUserLora = isMatching(
  // prettier-ignore
  P.union(
    GenerationModelType.UserLora,
    GenerationModelType.UserSdxlLora,
  ),
)

export const isXLLora = isMatching(
  // prettier-ignore
  P.union(
    GenerationModelType.SdxlLora,
    GenerationModelType.UserSdxlLora,
  ),
)

export function isModelCoverAppealable(model?: GenerationModel): boolean {
  return model?.flag?.isAppealable ?? false
}

export function shouldModelBeBlurred(model?: GenerationModel | null): boolean {
  return model?.flag?.shouldBlur ?? false
}

// Merged into "cover removed" state.
// Not actually useful at the moment.
function isModelCoverUnderReview(model?: GenerationModel): boolean {
  return model?.flag?.status === ModerationStatus.Human
}

export function isModelCoverWarned(model?: GenerationModel): boolean {
  return (isModelCoverUnderReview(model) || model?.flag?.isWarned) ?? false
}
