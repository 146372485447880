import enAction from './en/action.json'
import enArtwork from './en/artwork.json'
import enAuth from './en/auth.json'
import enComment from './en/comment.json'
import enCommon from './en/common.json'
import enError from './en/error.json'
import enGeneration from './en/generation.json'
import enGrowth from './en/growth.json'
import enManga from './en/manga.json'
import enMarket from './en/market.json'
import enMembership from './en/membership.json'
import enUser from './en/user.json'
import jaAction from './ja/action.json'
import jaArtwork from './ja/artwork.json'
import jaAuth from './ja/auth.json'
import jaComment from './ja/comment.json'
import jaCommon from './ja/common.json'
import jaError from './ja/error.json'
import jaGeneration from './ja/generation.json'
import jaGrowth from './ja/growth.json'
import jaMarket from './ja/market.json'
import jaMembership from './ja/membership.json'
import jaUser from './ja/user.json'

export const resources = {
  en: {
    auth: enAuth,
    common: enCommon,
    action: enAction,
    error: enError,
    manga: enManga,
    artwork: enArtwork,
    user: enUser,
    comment: enComment,
    market: enMarket,
    growth: enGrowth,
    generation: enGeneration,
    membership: enMembership,
  },
  ja: {
    auth: jaAuth,
    common: jaCommon,
    action: jaAction,
    error: jaError,
    artwork: jaArtwork,
    user: jaUser,
    comment: jaComment,
    market: jaMarket,
    growth: jaGrowth,
    generation: jaGeneration,
    membership: jaMembership,
  },
} as const
