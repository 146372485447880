import { fromPairs } from 'lodash-es'
import { SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BehaviorSubject } from 'rxjs'

export const getQs = (
  searchParams = new URL(window.location.href).searchParams,
) => {
  const qs = fromPairs([...searchParams.entries()])
  return qs
}

export const fromQs = (obj: Record<string, any>, init?: URLSearchParams) => {
  const searchParams = new URLSearchParams(init)
  Object.entries(obj).forEach(([k, v]) => {
    if (v === null) {
      searchParams.delete(k)
    } else if (v) {
      searchParams.set(k, v)
    }
  })
  return searchParams
}
export const formatQs = (obj: Record<string, any>, init?: URLSearchParams) => {
  return fromQs(obj, init).toString()
}

type Qs = Record<string, string>

const $qs = new BehaviorSubject<Qs>(getQs())

export const useSyncQs = () => {
  const [params] = useSearchParams()

  useEffect(() => {
    $qs.next(getQs(params))
  }, [params])
}

export const useQs = () => {
  const [params, setParams] = useSearchParams()

  const data = useMemo(() => {
    return getQs(params) as Qs
  }, [params])

  const setData = useCallback(
    (action: SetStateAction<Record<string, any>>) => {
      setParams(
        params => {
          const data = typeof action === 'function' ? action($qs.value) : action
          const newParams = fromQs(
            {
              ...$qs.value,
              ...data,
            },
            params,
          )

          const oldValue = $qs.value

          $qs.next(getQs(newParams))

          console.log('setQs', oldValue, data, $qs.value)

          return newParams
        },
        {
          replace: true,
        },
      )
    },
    [setParams], // prevent setParams sets previous path back
  )

  return [data, setData] as const
}

export const formatParamURL = (
  base: string,
  params: Record<string, string | undefined>,
) => {
  const url = new URL(base)
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value)
    }
  })
  return url.toString()
}
