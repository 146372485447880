import useSWR, { Arguments, mutate, SWRConfiguration } from 'swr'
import { config } from '../utils/config'
import { hash } from '../utils/hash'
import { OptionalArray } from '../utils/type'
import { ApiError, client } from './client'
import { Sdk } from './generated'

export const getSWRKey =
  (prefix: string) =>
  (...args: any[]) =>
    args.filter(i => i).length ? [prefix, ...args] : null

const fnId = <T extends (...args: any[]) => any>(fn: T) =>
  config.isProd || !fn.name ? hash(fn.toString()).toString(16) : fn.name

export const createKeyGetter = <T extends (...args: any[]) => any>(fn: T) => {
  return fn.length ? getSWRKey(fnId(fn)) : () => fnId(fn)
}

export const globalSWRConfig: SWRConfiguration = {
  onErrorRetry(err, _key, _config, revalidate, { retryCount }) {
    // Never retry on TypeError.
    if (err instanceof TypeError) return

    // Never retry on ApiError.
    if (err instanceof ApiError) return

    // Only retry up to 10 times.
    if (retryCount >= 10) return

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000)
  },
}

export const createSWR = <Fetcher extends (...args: any[]) => Promise<any>>(
  fn: Fetcher,
  opt?: SWRConfiguration,
) => {
  type Data = Awaited<ReturnType<Fetcher>>

  const getKey = createKeyGetter(fn)

  const t = {
    // for display function name in devtools
    [fn.name]: (...args: OptionalArray<Parameters<Fetcher>>) => {
      const key = getKey(...args)
      return useSWR<Data>(key, ([prefix, ...args]) => fn(...args), { ...opt })
    },
  }
  return t[fn.name]
}

export const createMutator = <Fetcher extends (...args: any[]) => Promise<any>>(
  fn: Fetcher,
) => {
  const getKey = createKeyGetter(fn)

  return (
    args?: Parameters<Fetcher>,
    data?: any,
    shouldRevalidate?: boolean,
  ) => {
    const key = args ? getKey(...args) : null
    mutate(key, data, shouldRevalidate)
  }
}

type OpRawVariable<T extends keyof Sdk> = Parameters<Sdk[T]>[0]
type OpVariable<T extends keyof Sdk> =
  OpRawVariable<T> extends Arguments ? OpRawVariable<T> : never
type OpResult<T extends keyof Sdk> = Awaited<ReturnType<Sdk[T]>>
export const createSWRForGQL = <
  const OpName extends keyof Sdk,
  TransformVariable extends
    | ((param?: any) => OpVariable<OpName> | undefined)
    | undefined,
  Reducer extends (result: OpResult<OpName>) => any = (
    result: OpResult<OpName>,
  ) => OpResult<OpName>,
>(
  operationName: OpName,
  {
    transform,
    reduce,
  }: {
    /**
     * Transform the variable before passing to the query.
     * When passing a param of `undefined`, the function will not be called.
     * @example (id: string) => (id ? { id } : undefined)
     * @returns query variables, or `undefined` to skip fetching
     */
    transform?: TransformVariable
    /**
     * Reduce the result before returning to the caller.
     */
    reduce?: Reducer
  } = {},
  defaultSWRConfig?: SWRConfiguration,
) => {
  type RawVariable = OpRawVariable<OpName>
  type Variable = OpVariable<OpName>
  type Result = OpResult<OpName>
  type Data = Reducer extends (...args: any[]) => infer R ? R : Result
  type Param = TransformVariable extends () => any
    ? never
    : TransformVariable extends (param: infer P) => any
      ? P
      : Variable

  const getKey = (variable: RawVariable) =>
    ['graphql-operation', operationName, variable] as const

  const fetcher = async ([type, operationName, variable]: ReturnType<
    typeof getKey
  >): Promise<Data> => {
    const result = (await client[operationName](variable as any)) as Result
    return reduce ? reduce(result) : result
  }

  /**
   * Instantiate useSWR with the given operation.
   * @param param query variable,
   * or `undefined` to skip fetching if transform is not provided
   */
  const useSWRNext = <T extends Data = Data>(
    param?: Param,
    swrConfig?: SWRConfiguration,
  ) => {
    const variable = transform ? transform(param) : param
    return useSWR<T>(
      variable !== undefined ? getKey(variable) : null,
      fetcher,
      { ...defaultSWRConfig, ...swrConfig },
    )
  }

  /**
   * Mutate the cache.
   * @param param query variable, or a function to match cache by key,
   * or `undefined` to match every cache of this operation.
   */
  const mutateNext = (
    param?: Param | ((key?: Arguments) => boolean),
    data?: Data,
    shouldRevalidate?: boolean,
  ) => {
    let variable: Arguments | ((key?: Arguments) => boolean)
    switch (typeof param) {
      case 'function': {
        variable = param
        break
      }
      case 'undefined': {
        variable = (key?: Arguments) =>
          Array.isArray(key) &&
          key[0] === 'graphql-operation' &&
          key[1] === operationName
        break
      }
      default: {
        const raw = transform ? transform(param) : param
        variable = raw !== undefined ? getKey(raw) : null
        break
      }
    }
    mutate<Data>(variable, data, shouldRevalidate)
  }

  return [useSWRNext, mutateNext] as const
}
