import { isPlainObject } from 'lodash-es'

export const attemptParseJSON = (str?: string | null) => {
  if (!str) return
  try {
    return JSON.parse(str)
  } catch (e) {}
}

export const flattenObject = (
  o: any,
  r: any = {},
  path: string[] = [],
  separator = '.',
) => {
  for (const [k, v] of Object.entries(o)) {
    if (isPlainObject(v)) {
      flattenObject(v, r, [...path, k], separator)
      continue
    }
    r[[...path, k].join(separator)] = v
  }
  return r
}
