import axios, { AxiosResponse } from 'axios'
import { GraphQLError } from 'graphql'
import { Observable } from 'rxjs'
import { getApiUrl } from 'src/utils/config'
import {
  getToken,
  setIntercomUserHash,
  setToken,
  unsetIntercomUserHash,
} from '../utils/token'
import { Requester, getSdk } from './generated'
import { graphqlWsClient } from './websocket'

const baseClient = axios.create({
  baseURL: getApiUrl(),
  validateStatus: null,
})

baseClient.interceptors.request.use(opt => {
  const token = getToken()
  if (token) {
    opt.headers = {
      ...opt.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return opt
})

baseClient.interceptors.response.use(res => {
  if (res.status === 401) {
    setToken('')
    unsetIntercomUserHash()
  } else {
    const token = res.headers.token
    if (token) {
      setToken(token)
    }
    const intercomUserHash = res.headers['intercom-user-hash']
    if (intercomUserHash) {
      setIntercomUserHash(intercomUserHash)
    }
  }
  return res
})

export const controlNetClient = axios.create({
  baseURL: 'https://controlnet.pixai.art',
})

controlNetClient.interceptors.request.use(opt => {
  const token = getToken()
  if (token) {
    opt.headers = {
      ...opt.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return opt
})

export const captioningClient = axios.create({
  baseURL: 'https://captioning.pixai.art',
})

captioningClient.interceptors.request.use(opt => {
  const token = getToken()
  if (token) {
    opt.headers = {
      ...opt.headers,
      Authorization: `Bearer ${token}`,
    }
  }
  return opt
})

export class ApiError extends Error {
  errors?: ReadonlyArray<GraphQLError>
  status?: number
  res?: AxiosResponse
}

export interface RequestOption {
  token?: string
  headers?: Record<string, string>
}

export type AppRequester = Requester<RequestOption, unknown>

const subscriptionRequester: AppRequester = (doc, vars) => {
  return new Observable<any>(subscriber => {
    graphqlWsClient.subscribe(
      {
        query: doc,
        variables: vars as any,
      },
      {
        next(value) {
          if (value.errors) {
            const err = new ApiError(value.errors[0].message)
            err.errors = value.errors
            subscriber.error(err)
          } else if (value.data) {
            subscriber.next(value.data)
          }
        },
        error(error) {
          subscriber.error(error)
        },
        complete() {
          subscriber.complete()
        },
      },
    )
  })
}

const normalRequester: AppRequester = async (
  doc,
  vars,
  { token, headers: _headers } = {},
) => {
  const headers: Record<string, string> = {
    ..._headers,
  }

  if (token) headers['Authorization'] = `Bearer ${token}`

  const res = await baseClient.post(
    '/graphql',
    {
      query: doc,
      variables: vars,
    },
    {
      headers,
    },
  )

  const data = res.data

  if (data.errors?.length) {
    const err = new ApiError(data.errors[0].message)
    err.status = res.status
    err.res = res
    err.errors = data.errors
    console.error(err)
    throw err
  }

  return data.data
}

const requester: AppRequester = (doc, vars, opt) => {
  if (doc.trimStart().startsWith('subscription')) {
    return subscriptionRequester(doc, vars, opt)
  }
  return normalRequester(doc, vars, opt)
}

export const client = getSdk(requester)

window.client = client
