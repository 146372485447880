import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './components/OnboardingTutorial/clean-tracks'
import './i18n/i18next'
import './index.css'
import './utils/sentry'

function restoreAPIURLFromQueryString() {
  const location = window.location.href
  const url = new URL(location)
  const apiURL = url.searchParams.get('withAPIURL')
  if (apiURL) {
    localStorage.setItem(
      'api-base-url',
      JSON.stringify(decodeURIComponent(apiURL)),
    )
    url.searchParams.delete('withAPIURL')
    window.history.replaceState({}, '', url.toString())
  }
}
restoreAPIURLFromQueryString()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
