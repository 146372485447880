import { ComponentType, DetailedHTMLProps, MetaHTMLAttributes } from 'react'
import {
  Navigate,
  NonIndexRouteObject,
  createBrowserRouter,
  redirect,
} from 'react-router-dom'
import { setRoutes as setRoutesForTracker } from 'src/utils/tracker'
import ErrorPage from './components/ErrorPage'

import {
  rankingPageRouterLoader,
  rankingTypePageRouterLoader,
} from './components/Ranking/ranking-utils'
import Root from './pages/Root'

export interface AppRouteHandle {
  name: string
  meta?: Array<
    DetailedHTMLProps<MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>
  >
  hideMembershipBanner?: boolean
}

export interface AppRouteObject extends NonIndexRouteObject {
  id?: never // force no id to use default index id pattern
  handle: AppRouteHandle
  children?: AppRouteObject[]
}

// @see https://github.com/remix-run/react-router/issues/10475#issuecomment-1665464922
async function passProps<T, P>(
  loader: Promise<T & { Component: ComponentType<P> }>,
  props: JSX.IntrinsicAttributes & P,
) {
  const mod = await loader
  return {
    ...mod,
    Component: undefined,
    element: <mod.Component {...props!} />,
  }
}

export const routes: AppRouteObject[] = [
  {
    handle: { name: 'root' },
    path: '/*',
    errorElement: <ErrorPage />,
    element: <Root />,
    children: [
      {
        handle: { name: 'error' },
        path: 'error',
        element: <ErrorPage />,
      },
      {
        handle: { name: 'admin' },
        path: 'admin/*',
        lazy: () => import('./pages/Admin'),
      },
      {
        handle: { name: 'privacy' },
        path: 'privacy',
        lazy: () => import('./pages/Privacy'),
      },
      {
        handle: { name: 'terms' },
        path: 'terms',
        lazy: () => import('./pages/Terms'),
      },
      {
        handle: { name: 'login' },
        path: 'login',
        lazy: () => passProps(import('./pages/SignPage'), { isLogin: true }),
      },
      {
        handle: { name: 'sign-up' },
        path: 'sign-up',
        lazy: () => import('./pages/SignPage'),
      },
      {
        handle: { name: 'verify-email' },
        path: 'verify-email',
        lazy: () => import('./pages/VerifyEmail'),
      },
      {
        handle: { name: 'referral' },
        path: 'referral',
        lazy: () => import('./pages/ConfirmReferral'),
      },
      {
        handle: { name: 'reset-password' },
        path: 'reset-password',
        lazy: () => import('./pages/ResetPassword'),
      },
      {
        handle: { name: 'forget-password/reset' },
        path: 'forget-password/reset',
        lazy: () =>
          passProps(import('./pages/ResetPassword'), {
            isForgetPassword: true,
          }),
      },
      {
        handle: { name: 'change-email' },
        path: 'change-email',
        lazy: () => import('./pages/ChangeEmail'),
      },
      {
        handle: { name: 'forget-password' },
        path: 'forget-password',
        lazy: () => import('./pages/ForgetPassword'),
      },
      {
        handle: { name: 'manga-detail' },
        path: 'manga/:id',
        lazy: () => import('./pages/MangaDetailPage'),
      },
      {
        handle: { name: 'app' },
        path: '*',
        lazy: () => import('./pages/AppPage'),
        children: [
          {
            handle: { name: 'bot/discord/landing' },
            path: 'bot/discord/landing',
            lazy: () => import('./pages/DiscordBotEntrypoint'),
          },
          {
            handle: { name: 'manga/upload' },
            path: 'manga/upload',
            lazy: () => import('./pages/MangaUploadPage'),
          },
          {
            handle: { name: 'manga/create' },
            path: 'manga/create',
            lazy: () => import('./components/Manga/Edit/EditMangaPage'),
          },
          {
            handle: { name: 'profile/edit' },
            path: 'profile/edit',
            lazy: () => import('./pages/ProfileEditPage'),
          },
          {
            handle: { name: 'me/*' },
            path: 'me/*',
            lazy: () =>
              passProps(import('./pages/UserProfilePage'), { isMe: true }),
          },
          {
            handle: { name: 'submit/upload' },
            path: 'submit/upload',
            lazy: () => import('./pages/ArtworkSubmitUploadPage'),
          },
          {
            handle: { name: 'tasks' },
            path: 'tasks',
            element: <Navigate to="/me/tasks" />,
          },
          {
            handle: { name: 'feedback' },
            path: 'feedback',
            lazy: () => import('./pages/Feedback'),
          },
          {
            handle: { name: 'artwork-detail' },
            path: 'artwork/:id',
            lazy: () => import('./pages/ArtworkDetailPage'),
          },
          {
            handle: { name: 'video-artwork-detail' },
            path: 'video/:id',
            lazy: () => import('./pages/ArtworkDetailPage'),
          },
          {
            handle: { name: 'animated-comic-detail' },
            path: 'animated-comic/:id',
            lazy: () => import('./pages/AnimatedComicDetailPage'),
          },
          {
            handle: { name: 'artwork-relevant-artworks' },
            path: 'artwork/:id/relevant',
            lazy: () => import('./pages/ArtworkRelevantArtworksPage'),
          },
          {
            handle: { name: 'model-detail' },
            path: 'model/:modelId',
            lazy: () => import('./pages/ModelDetailPage'),
          },
          {
            handle: { name: 'model-version-detail' },
            path: 'model/:modelId/:versionId',
            lazy: () => import('./pages/ModelDetailPage'),
          },
          {
            handle: { name: 'market' },
            path: 'market',
            lazy: () => import('./pages/AppPage/MarketPage'),
          },
          {
            handle: { name: 'ranking' },
            path: 'ranking',
            lazy: () => import('./pages/RankingPage'),
            loader: rankingPageRouterLoader,
          },
          {
            handle: { name: 'ranking' },
            path: 'ranking/:type/:filterType',
            lazy: () => import('./pages/RankingPage'),
            loader: rankingTypePageRouterLoader,
          },
          {
            handle: { name: 'market/submit' },
            path: 'market/submit',
            lazy: () => import('./pages/ModelSubmitPage'),
            children: [
              {
                handle: { name: 'market/submit/step-1' },
                path: 'step-1',
                lazy: () =>
                  import(
                    'src/components/Market/ModelUploadPages/ModelUploadStepOne'
                  ),
              },
              {
                handle: { name: 'market/submit/step-2' },
                path: 'step-2',
                lazy: () =>
                  import(
                    'src/components/Market/ModelUploadPages/ModelUploadStepTwo'
                  ),
              },
              {
                handle: { name: 'market/submit/step-3' },
                path: 'step-3',
                lazy: () =>
                  import(
                    'src/components/Market/ModelUploadPages/ModelUploadStepThree'
                  ),
              },
            ],
          },

          {
            handle: { name: 'search' },
            path: 'search',
            lazy: () => import('./pages/AppPage/SearchResultPage'),
          },
          {
            handle: { name: 'artwork/tags' },
            path: 'artwork/tags/:tag',
            lazy: () =>
              passProps(import('./pages/AppPage/TagPage'), { type: 'artwork' }),
          },
          {
            handle: { name: 'model/tags' },
            path: 'model/tags/:tag',
            lazy: () =>
              passProps(import('./pages/AppPage/TagPage'), { type: 'model' }),
          },
          {
            handle: { name: 'train-lora' },
            path: 'train-lora',
            lazy: () => import('./pages/TrainLoraPage'),
          },

          /**
           * Membership
           */

          {
            handle: { name: 'membership/plans', hideMembershipBanner: true },
            path: 'membership/plans',
            lazy: () => import('./pages/Membership/MembershipPlansPage'),
          },
          {
            handle: { name: 'membership/current', hideMembershipBanner: true },
            path: 'membership/current',
            lazy: () => import('./pages/Membership/MembershipCurrentPlanPage'),
          },
          // Subscription result back from stripe page
          // Subscription doesn't have order ID
          {
            handle: {
              name: 'payment/subscription/landing',
              hideMembershipBanner: true,
            },
            path: 'payment/subscription/landing',
            lazy: () => import('./pages/Membership/SubscriptionLanding'),
          },
          // Results are known, not getting back from stripe
          // Only subscription results are known for now
          {
            handle: { name: 'payment/result', hideMembershipBanner: true },
            path: 'payment/subscription/result',
            lazy: () => import('./pages/Membership/SubscriptionUpdateResult'),
          },
          {
            handle: {
              name: 'payment/paddle/failed',
              hideMembershipBanner: true,
            },
            path: 'payment/paddle/failed',
            lazy: () => import('./pages/Membership/PaddlePaymentFailed'),
          },

          /**
           * ---
           */

          // User routes don't start with `@` since https://github.com/remix-run/react-router/pull/9506
          {
            handle: { name: 'bookmark-artwork' },
            path: ':usernameRaw/collection/:collectionId',
            lazy: () => import('./pages/UserProfilePage'),
          },
          {
            handle: { name: 'profile' },
            path: ':usernameRaw/:tab/:subTab',
            lazy: () => import('./pages/UserProfilePage'),
          },
          {
            handle: { name: 'profile' },
            path: ':usernameRaw/:tab',
            lazy: () => import('./pages/UserProfilePage'),
          },
          {
            handle: { name: 'profile' },
            path: ':usernameRaw',
            lazy: () => import('./pages/UserProfilePage'),
          },

          {
            handle: { name: 'news-list' },
            path: 'news',
            lazy: () => import('src/pages/NewsListPage'),
          },
          {
            handle: { name: 'news-detail' },
            path: 'news/:id',
            lazy: () => import('./pages/NewsDetailPage'),
          },

          {
            handle: { name: 'home' },
            path: '*',
            lazy: () => import('./pages/AppPage/HomePageWrapper'),
          },

          // Contest
          {
            handle: { name: 'contest' },
            path: 'contest',
            lazy: () => import('./pages/ContestPage'),
          },
          {
            handle: { name: 'contest' },
            path: 'contest/:id',
            lazy: () => import('./pages/ContestPage'),
          },
        ],
      },

      /**
       * ==== Generator ========================================================
       */

      {
        handle: { name: 'generator' },
        path: 'generator',
        loader: ({ request }) => {
          const url = new URL(request.url)
          url.pathname = '/generator/image'
          return redirect(url.toString())
        },
      },
      {
        handle: { name: 'generator/image' },
        path: 'generator/image',
        lazy: () => import('./pages/MultiColumnSubmitPage'),
      },
      {
        handle: { name: 'generator/animated-comic' },
        path: 'generator/animated-comic',
        lazy: () => import('./pages/AnimatedComicGeneratorPage'),
      },
      {
        handle: { name: 'generator/realtime', hideMembershipBanner: true },
        path: 'generator/realtime',
        loader: ({ request }) => {
          const url = new URL(request.url)
          url.pathname = '/generator/realtime/drawing'
          return redirect(url.href)
        },
      },
      {
        handle: {
          name: 'generator/realtime/:mode',
          hideMembershipBanner: true,
        },
        path: 'generator/realtime/:mode',
        loader: ({ request, params }) => {
          if (params.mode !== 'drawing') {
            const url = new URL(request.url)
            url.pathname = '/generator/realtime/drawing'
            return redirect(url.href)
          }
          return null
        },
        lazy: () => import('./pages/LCMRealtime'),
      },
      // Legacy path compatibility
      {
        handle: { name: 'realtime' },
        path: 'realtime',
        loader: ({ request }) => {
          const url = new URL(request.url)
          return redirect('/generator/realtime?' + url.searchParams.toString())
        },
      },
      {
        handle: { name: 'submit' },
        path: 'submit',
        loader: ({ request }) => {
          const url = new URL(request.url)
          url.pathname = '/generator'
          return redirect(url.toString())
        },
      },
      {
        handle: { name: 'submit/gen' },
        path: 'submit/gen',
        loader: ({ request }) => {
          const url = new URL(request.url)
          return redirect(
            '/generator/image?mode=gen&' + url.searchParams.toString(),
          )
        },
      },
      {
        handle: { name: 'submit/fill' },
        path: 'submit/fill',
        loader: ({ request }) => {
          const url = new URL(request.url)
          return redirect(
            '/generator/image?mode=fill&' + url.searchParams.toString(),
          )
        },
      },
    ],
  },
]
setRoutesForTracker(routes)

export const router = createBrowserRouter(routes)
