import { Experiment } from '@amplitude/experiment-js-client'
import { useUserInfo } from 'src/services'
import { createSWR } from 'src/services/swr'
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage'
import { config } from './config'

/** Name: web_first-deployment */
let experiment: ReturnType<(typeof Experiment)['initialize']>

async function initExperimentClient() {
  experiment = Experiment.initializeWithAmplitudeAnalytics(
    config.amplitudeExperimentDeploymentKey,
    {
      serverUrl: config.amplitudeExperimentsServerUrl,
    },
  )
  await experiment.start()
  return experiment
}

export async function getExperimentParam<T>(
  experimentName: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userId?: string,
) {
  if (!experiment) {
    experiment = await initExperimentClient()
  } else {
    // Refetch for most up-to-date variants
    await experiment.fetch()
  }
  const variant = experiment.variant(experimentName)
  return variant.value as T
}

const _useExperimentParam = createSWR(getExperimentParam, {
  refreshInterval: 10 * 60 * 1000, // 10 min
})

/**
 * @param experimentName
 * @param defaultValue
 * @returns Returns `undefined` if experiment isn't enabled or doesn't exist.
 */
export function useExperimentParam<T>(
  experimentName: string,
  defaultValue?: T,
) {
  const { data: userInfo } = useUserInfo()
  const fallbackDefault = defaultValue ?? undefined
  const { data: experimentData } = _useExperimentParam(
    experimentName,
    userInfo?.id, // Refetch experiment param on userId changes
  )
  const [localValue] = useLocalStorage<T>(`exp:${experimentName}`)
  return localValue ?? (experimentData as T) ?? fallbackDefault
}
