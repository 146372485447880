import { useEffect } from 'react'
import { Outlet, useMatches } from 'react-router-dom'
import { AppRouteHandle } from 'src/router'
import { setHeaderBanner } from 'src/stores/banner'
import { useCommonTokenHandler } from 'src/utils/auth'
import { DynamicCreateElementContainer } from 'src/utils/dynamic'
import { useSyncQs } from 'src/utils/qs'
import { endEventGroup, useTrackPageView } from 'src/utils/tracker'

const Root = () => {
  useSyncQs()
  useCommonTokenHandler()
  useTrackPageView()

  useEffect(() => {
    // Ensure all remained event groups being cleared after a page reload.
    // Wrapping in an effect is to ensure the first page_view event being with
    // an event group.
    endEventGroup(true)
  }, [])

  return (
    <>
      <NavigationListener />
      <DynamicCreateElementContainer>
        <Outlet />
      </DynamicCreateElementContainer>
    </>
  )
}

export default Root

function NavigationListener() {
  const state: Record<string, any> = {}
  const matches = useMatches()

  for (const route of matches) {
    const handle = route.handle as AppRouteHandle

    if (handle.hideMembershipBanner != null) {
      state.hideMembershipBanner = handle.hideMembershipBanner
    }
  }

  setHeaderBanner(!state.hideMembershipBanner)

  return null
}
