/**
 * Model Store
 *
 * Currently only stores model (including LoRAs) versions
 */

import { atom, getDefaultStore, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { useMemo } from 'react'
import { client } from 'src/services/client'
import {
  GenerationModelVersion,
  GetGenerationModelByVersionIdQuery,
} from 'src/services/generated'

const store = getDefaultStore()

type ModelVersion = GetGenerationModelByVersionIdQuery['generationModelVersion']

export const modelCacheAtom = atom(
  {} as Record<string, undefined | { value: ModelVersion; updatedAt: number }>,
)

const THROTTLE = 6e5 // 10 minutes

/**
 * Get a model version info
 *
 * Always returns the cached data, but fires a sync automatically. Recommended
 * to use the hook `useModelVersion` instead, unless you really intend to access
 * values from cache directly.
 */
export function getModelVersion(versionId: string | undefined) {
  if (!versionId) return

  const cached = store.get(modelCacheAtom)[versionId]
  if (!cached || Date.now() - cached.updatedAt >= THROTTLE) {
    syncModelVersion(versionId)
  }
  return cached?.value
}

/**
 * Save a mode version info to cache
 */
export function setModelVersion(versionId: string, model: ModelVersion) {
  store.set(modelCacheAtom, cache => ({
    ...cache,
    [versionId]: { value: model, updatedAt: Date.now() },
  }))
}

const fetchers = new Map<string, Promise<GenerationModelVersion | undefined>>()

// prettier-ignore
async function fetchModelVersion(id: string): Promise<GenerationModelVersion | undefined> {
  if (fetchers.has(id)) {
    return fetchers.get(id)
  } else {
    const fetcher = client
      .getGenerationModelByVersionId({ id })
      .then(data => {
        const version = data.generationModelVersion
        setModelVersion(id, version)
        return version
      })
      .finally(() => {
        fetchers.delete(id)
      })
    fetchers.set(id, fetcher)
    return fetcher
  }
}

/**
 * Sync a model version info from server
 */
export async function syncModelVersion(versionId: string) {
  await fetchModelVersion(versionId)
}

type UseModelVersionReturn<T> = T extends string[]
  ? Record<T[number], ModelVersion | undefined>
  : T extends string
  ? ModelVersion | undefined
  : undefined

export function useModelVersion<T extends undefined | string | string[]>(
  versionId: T,
): UseModelVersionReturn<T> {
  // Activate the jotai effect to track state changes.
  // Only the requested versions are needed to track.
  // So select them for better performance.
  useAtomValue(
    useMemo(
      () =>
        selectAtom(
          modelCacheAtom,
          cache => {
            if (!versionId) return undefined
            if (typeof versionId === 'string') return cache[versionId]
            // Otherwise, it's an array
            return versionId.map(id => cache[id])
          },
          // Extracts concatenated `updatedAt` for equality check
          (a, b) =>
            (Array.isArray(a)
              ? // prettier-ignore
                a.map(it => it?.updatedAt).sort().join()
              : a?.updatedAt) ===
            (Array.isArray(b)
              ? // prettier-ignore
                b.map(it => it?.updatedAt).sort().join()
              : b?.updatedAt),
        ),
      [versionId],
    ),
  )

  if (!versionId) return undefined as UseModelVersionReturn<T>
  return (
    typeof versionId === 'string'
      ? getModelVersion(versionId)
      : Object.fromEntries(versionId.map(id => [id, getModelVersion(id)]))
  ) as UseModelVersionReturn<T>
}
