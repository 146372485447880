import { TFunction } from 'i18next'
import { LoaderFunction, redirect } from 'react-router-dom'
import { Notification, RankMediaType } from 'src/services/generated'
import dayjs from 'src/utils/dayjs'
import { match } from 'ts-pattern'
import { ValueOf } from 'type-fest'

export const RankingContentType = {
  Artwork: 'artwork',
  Animated: 'animated',
  Comic: 'comic',
  Model: 'model',
  Lora: 'lora',
} as const
export type RankingContentTypeValues = ValueOf<typeof RankingContentType>

export const RankingRangeType = {
  Today: 'today',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Newbies: 'newbies',
} as const
export type RankingRangeTypeValues = ValueOf<typeof RankingRangeType>

export const rankingPageRouterLoader: LoaderFunction = async () => {
  return redirect(
    `/ranking/${RankingContentType.Artwork}/${RankingRangeType.Today}`,
  )
}

export const rankingTypePageRouterLoader: LoaderFunction = async ({
  params,
}) => {
  const type = params.type as RankingContentTypeValues
  const filterType = params.filterType as RankingRangeTypeValues
  if (
    Object.values(RankingContentType).includes(type) &&
    Object.values(RankingRangeType).includes(filterType)
  ) {
    return null
  }

  return redirect(
    `/ranking/${RankingContentType.Artwork}/${RankingRangeType.Today}`,
  )
}

export const getDayToDisplayForRanking = () => {
  const today = new Date()

  return dayjs(today).format('MMM DD')
}

export const getRankingPageTitle = (
  t: TFunction<'common'[]>,
  type: RankingContentTypeValues,
  filterType: RankingRangeTypeValues,
) => {
  const typeLabelStr = t(`common:ranking.${type}.label`)
  return match(filterType)
    .with(RankingRangeType.Today, () =>
      t('common:ranking.title.daily-artwork', { type: typeLabelStr }),
    )
    .with(RankingRangeType.Weekly, () => {
      const today = new Date()
      const weekBefore = dayjs(today).subtract(6, 'day')
      const dateRangeStr =
        dayjs(weekBefore).format('MMM DD') + '-' + dayjs(today).format('MMM DD')

      return t('common:ranking.title.filter-type-artwork', {
        filterType: dateRangeStr,
        type: typeLabelStr,
      })
    })
    .with(RankingRangeType.Monthly, () => {
      const today = new Date()
      const monthStr = dayjs(today).format('MMM')

      return t('common:ranking.title.filter-type-artwork', {
        filterType: monthStr,
        type: typeLabelStr,
      })
    })
    .with(RankingRangeType.Newbies, () =>
      t('common:ranking.title.filter-type-artwork', {
        filterType: t('common:ranking.filter-type.newbies'),
        type: typeLabelStr,
      }),
    )
    .exhaustive()
}

export const getRankingPageGridTitle = (
  t: TFunction<'common'[]>,
  type: RankingContentTypeValues,
  filterType: RankingRangeTypeValues,
) => {
  const typeLabelStr = t(`common:ranking.${type}.label`)
  return match(filterType)
    .with(RankingRangeType.Today, () =>
      t('common:ranking.title.filter-type-artwork', {
        type: typeLabelStr,
        filterType: t('common:ranking.filter-type.daily'),
      }),
    )
    .with(RankingRangeType.Weekly, () =>
      t('common:ranking.title.filter-type-artwork', {
        type: typeLabelStr,
        filterType: t('common:ranking.filter-type.weekly'),
      }),
    )
    .with(RankingRangeType.Monthly, () =>
      t('common:ranking.title.filter-type-artwork', {
        filterType: t('common:ranking.filter-type.monthly'),
        type: typeLabelStr,
      }),
    )
    .with(RankingRangeType.Newbies, () =>
      t('common:ranking.title.filter-type-artwork', {
        filterType: t('common:ranking.filter-type.newbies'),
        type: typeLabelStr,
      }),
    )
    .exhaustive()
}

export const getArtworkTypeLabelKeyForRanking = (
  item?: Notification,
): RankingContentTypeValues => {
  switch (item?.data?.rank?.mediaType) {
    case RankMediaType.Comic:
      return RankingContentType.Comic
    case RankMediaType.ArtworkAnimated:
      return RankingContentType.Animated
    default:
      return RankingContentType.Artwork
  }
}
