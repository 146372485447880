import { Autocomplete, Button, TextField } from '@mui/material'
import { compact, uniq } from 'lodash-es'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { config } from 'src/utils/config'
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage'

const WebsocketBaseUrlSelect = () => {
  const [websocketBaseUrl, setWebsocketBaseUrl] = useLocalStorage(
    'gateway-base-url',
    config.gatewayUrl,
  )
  const [websocketBaseUrlOptions, setWebsocketBaseUrlOptions] = useLocalStorage<
    string[]
  >('gateway-base-url-hist', [])
  const [websocketBaseUrlValue, setWebsocketBaseUrlValue] =
    useState(websocketBaseUrl)

  const submitWebsocketBaseUrl = () => {
    setWebsocketBaseUrl(websocketBaseUrlValue)
    setWebsocketBaseUrlOptions(values => {
      return uniq([websocketBaseUrlValue, ...values]).slice(0, 10)
    })
    toast.success(
      `Changed to ${websocketBaseUrlValue}. Refresh to take effect.`,
    )
  }

  return (
    <div className="w-full flex gap-4">
      <Autocomplete
        freeSolo
        options={compact(
          uniq([
            'wss://gw.pixai.art',
            'wss://pixai-server-gateway-main.ring.mewtant.co',
            'ws://127.0.0.1:3015',
            'ws://dev.local:3015',
            config.gatewayUrl,
            ...websocketBaseUrlOptions,
          ]),
        )}
        className="flex-1"
        size="small"
        renderInput={params => {
          return <TextField label="WebSocket Base URL" {...params} />
        }}
        placeholder={config.gatewayUrl}
        value={websocketBaseUrlValue}
        onInputChange={(_, v) => {
          setWebsocketBaseUrlValue(v ?? '')
        }}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            submitWebsocketBaseUrl()
          }
        }}
      />
      <Button
        variant="contained"
        onClick={() => {
          submitWebsocketBaseUrl()
        }}
      >
        save
      </Button>
    </div>
  )
}

export default WebsocketBaseUrlSelect
