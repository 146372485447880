import { getDeviceId } from '@amplitude/analytics-browser'
import {
  IconButton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { FC } from 'react'
import { useMount } from 'react-use'
import { useUserInfo } from 'src/services'
import { P, match } from 'ts-pattern'
import MdiCancel from '~icons/mdi/cancel'
import MdiContentCopy from '~icons/mdi/content-copy'
import { useExperimentParam } from '../../../utils/experiment'
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage'
import { copy } from '../../SocialShare/SocialShare'
import ApiBaseUrlSelect from './ApiBaseUrlSelect'
import WebsocketBaseUrlSelect from './WebsocketBaseUrlSelect'

const DebugSettings = () => {
  const deviceId = getDeviceId()
  const { data: user } = useUserInfo()

  return (
    <>
      <ApiBaseUrlSelect />
      <WebsocketBaseUrlSelect />

      <div className="flex flex-col gap-4">
        <h3 className="text-xl font-medium">Feature Flags</h3>
        <div>
          <span className="text-sm">DeviceID:&nbsp;</span>
          <code
            className="inline-flex items-center text-sm cursor-pointer"
            onClick={() => copy(deviceId ?? '', { scene: null })}
          >
            {deviceId}
            <MdiContentCopy className="w-5 h-5 ml-2" />
          </code>
        </div>
        <div>
          <span className="text-sm">UserID:&nbsp;</span>
          <code
            className="inline-flex items-center text-sm cursor-pointer"
            onClick={() => copy(user?.id ?? '', { scene: null })}
          >
            {user?.id}
            <MdiContentCopy className="w-5 h-5 ml-2" />
          </code>
        </div>
        <div className="self-start grid grid-cols-[auto_auto] items-center justify-items-start gap-x-8">
          <FeatureFlag name="enableBraintreePayment" />
          <FeatureFlag name="simple-home" revoke="2024-03-19" />
          <FeatureFlag
            name="membership_inline-paddle-checkout"
            values={['overlay', 'inline']}
          />
          <FeatureFlag name="enable-animated-comic" />
          <FeatureFlag name="growth_show-ads" values={['on', 'off']} />
          {/* Keep wrapping */}
        </div>
      </div>
    </>
  )
}

export default DebugSettings

/**
 * Feature Flag
 */

const FeatureFlag: FC<{
  name: string
  /**
   * The storage key to use (if different from `exp:${name}`)
   */
  storageKey?: string
  /**
   * The values to choose from (if different from `true`/`false`)
   */
  values?: string[]
  /**
   * To render as a button to clear the stored key
   */
  clear?: boolean
  /**
   * To revoke an experiment (silently remove the key)
   *
   * The string value is the date of the revocation.
   */
  revoke?: string
}> = ({ name, storageKey = 'exp:' + name, values, clear, revoke }) => {
  const flag = useExperimentParam<boolean>(name)
  const [value, setValue] = useLocalStorage<unknown>(storageKey, flag)

  function onChange(ev: unknown, value: unknown) {
    setValue(value)
    if (!value) localStorage.removeItem(storageKey)
  }

  useMount(() => {
    if (revoke) onChange(null, 0)
  })

  if (revoke) return null

  return (
    <div className="contents">
      <code>{name}</code>
      {match({ values })
        .when(
          () => clear,
          () => (
            <IconButton size="small" onClick={() => onChange(null, 0)}>
              <MdiCancel />
            </IconButton>
          ),
        )
        .with({ values: P.array(P.any) }, ({ values }) => (
          <ToggleButtonGroup
            exclusive
            size="small"
            value={value}
            onChange={onChange}
          >
            <ToggleButton value="" className="px-4">
              <MdiCancel />
            </ToggleButton>
            {values.map(value => (
              <ToggleButton key={value} value={value}>
                {value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        ))
        .otherwise(() => (
          <Switch checked={Boolean(value)} onChange={onChange} />
        ))}
    </div>
  )
}
