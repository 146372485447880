import {
  ThemeOptions,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles'

const common: ThemeOptions = {
  palette: {},
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, system-ui, -apple-system, sans-serif',
  },
  shape: {
    borderRadius: 8,
  },
}

export const lightTheme = unstable_createMuiStrictModeTheme({
  ...common,
  palette: {
    ...common.palette,
    mode: 'light',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#efefef',
      contrastText: '#000',
    },
  },
})

export const darkTheme = unstable_createMuiStrictModeTheme({
  ...common,
  palette: {
    ...common.palette,
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
})
