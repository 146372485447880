import { AnchorHTMLAttributes, FC, ReactNode } from 'react'
import { cx } from 'src/utils/cx'
export const OutboundLink: FC<
  {
    target?: HTMLAnchorElement['target']
    children?: ReactNode
  } & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel'>
> = ({ target = '_blank', children, ...props }) => (
  <a
    target={target}
    rel="noopener"
    className={cx('break-all', props.className)}
    {...props}
  >
    {children}
  </a>
)
