import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { Media } from 'src/services/generated'
import { config } from 'src/utils/config'
import { getSmartThumbnailUrl } from 'src/utils/media'
import { joinURL, resolveURL } from 'ufo'

const SITE_NAME = 'PixAI'
const ENV_STR = `${config.isDev ? '[DEV]' : ''}${SITE_NAME}`
const ORIGIN = globalThis.window?.location.origin || 'https://pixai.art'

export function MetaTitle(props: {
  content?: string
  options?: {
    suffix?: string
  }
}) {
  const { content, options } = props

  const title = useMemo(() => {
    const suffix = `${ENV_STR}${options?.suffix ? ` - ${options?.suffix}` : ''}`
    if (content) return content + ' | ' + suffix
    return suffix
  }, [content, options?.suffix])

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
    </Helmet>
  )
}

export function MetaDescription(props: { content?: string }) {
  const { content } = props
  return (
    <Helmet>
      <meta name="description" content={content} />
      <meta property="og:description" content={content} />
      <meta name="twitter:description" content={content} />
    </Helmet>
  )
}

export function MetaOgUrl(props: { pathname?: string }) {
  const url = joinURL(ORIGIN, props.pathname ?? '')
  return (
    <Helmet>
      <meta property="og:url" content={url} />
      <meta name="twitter:url" content={url} />
    </Helmet>
  )
}

export function MetaThumbnail(props: { media?: Media }) {
  const thumbnailUrl = getSmartThumbnailUrl(props.media)
  if (thumbnailUrl) {
    return (
      <Helmet>
        <meta property="og:image" content={thumbnailUrl} />
      </Helmet>
    )
  }
  return null
}

export function MetaCanonicalURL(props: { href: string }) {
  return (
    <Helmet>
      <link rel="canonical" href={resolveURL(ORIGIN, props.href)} />
    </Helmet>
  )
}

export function DefaultHelmetTags() {
  return (
    <Helmet>
      <meta
        name="description"
        content="PixAI is a community where you can share and browse artworks generated by AI with text. You can also generate your own artworks here."
      />
      <meta property="og:url" content={ORIGIN} />
      <meta
        property="og:image"
        content="https://imagedelivery.net/5ejkUOtsMH5sf63fw6q33Q/0d8c0aee-c2eb-436a-a70d-262b4a9a0700/thumbnail"
      />
      <meta
        name="twitter:title"
        content="PixAI - Anime AI Art Generator for Free"
      />
      <meta
        name="twitter:description"
        content="PixAI is a community where you can share and browse artworks generated by AI with text. You can also generate your own artworks here."
      />
      <meta
        property="og:title"
        content="PixAI - Anime AI Art Generator for Free"
      />
      <meta
        property="og:description"
        content="PixAI is a community where you can share and browse artworks generated by AI with text. You can also generate your own artworks here."
      />
    </Helmet>
  )
}

export default function MetaTags(props: {
  title?: string
  titleOptions?: {
    suffix?: string
  }
  description?: string
  thumbnailMedia?: Media
  ogUrlPathname?: string
  canonicalURL?: string
}) {
  const {
    title,
    titleOptions,
    description,
    thumbnailMedia,
    ogUrlPathname,
    canonicalURL,
  } = props
  return (
    <>
      {title && <MetaTitle content={title} options={titleOptions} />}
      {description && <MetaDescription content={description} />}
      {thumbnailMedia && <MetaThumbnail media={thumbnailMedia} />}
      {/* TODO: og-url may use canonical URL by default */}
      {ogUrlPathname && <MetaOgUrl pathname={ogUrlPathname} />}
      {canonicalURL && <MetaCanonicalURL href={canonicalURL} />}
    </>
  )
}
