import { Button } from '@mui/material'
import { captureException } from '@sentry/browser'
import { compact } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useRouteError } from 'react-router-dom'
import { useLocalStorage } from 'src/utils/hooks/useLocalStorage'
import emptyImgPng from '../assets/pixchan/pixchan-qvq.png'
import emptyImgWebp from '../assets/pixchan/pixchan-qvq.webp'
import { PictureWithSources } from './Common/PictureWithSources'

const ErrorPage = () => {
  const error = useRouteError()
  const { t } = useTranslation('common')

  const [enableDebug] = useLocalStorage<boolean>('enable-debug')

  useEffect(() => {
    if (error) captureException(error)
  }, [error])

  const errorDetail = useMemo((): string => {
    if (!error || typeof error !== 'object')
      return String(error) || 'No error detail'
    const message = 'message' in error ? error.message : String(error)
    const stack = 'stack' in error ? error.stack : ''
    return compact([message, stack]).join('\n\n')
  }, [error])

  return (
    <div className="min-h-screen px-4 py-8 flex flex-col gap-8 justify-center items-center">
      <PictureWithSources
        pngSrc={emptyImgPng}
        webpSrc={emptyImgWebp}
        width={300}
        height={300}
      />
      <div className="text-primary-light text-lg ">
        {t('common:app-crashed')}
      </div>
      <div className="flex gap-2">
        {location.pathname !== '/' && (
          <Button variant="contained" component={Link} to="/">
            {t('homepage')}
          </Button>
        )}
        <Button variant="contained" onClick={() => location.reload()}>
          {t('common:reload')}
        </Button>
      </div>

      {enableDebug && (
        <pre className="max-w-full overflow-auto text-xs p-4 border rounded-lg font-mono">
          {errorDetail}
        </pre>
      )}
    </div>
  )
}

export default ErrorPage
