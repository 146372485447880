import { Observable, Subject, filter, map } from 'rxjs'
import { mutateTask } from '.'
import { client } from './client'
import { Events, Notification, Task } from './generated'

const $personalEvents = client.subscribePersonalEvents({}).pipe(
  map(e => e.personalEvents),
  filter(e => e != null),
) as Observable<Events>

window.$personalEvents = $personalEvents

const mapEvents = <K extends keyof Events, R = NonNullable<Events[K]>>(
  $events: Observable<Events>,
  key: K,
) =>
  $events.pipe(
    map(e => e[key] as R),
    filter(e => e != null),
  )

export const $taskUpdated = new Subject<Task>()
export const $newNotification = new Subject<Notification>()

mapEvents($personalEvents, 'taskUpdated').subscribe($taskUpdated)
mapEvents($personalEvents, 'newNotification').subscribe($newNotification)

$taskUpdated.subscribe(task => {
  mutateTask(task.id, task, false)
})
