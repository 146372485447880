import { useRef, type CSSProperties } from 'react'
import { useIntersection } from 'react-use'
import { cx } from 'src/utils/cx'
import styles from './Preloader.module.css'

export const Preloader = ({
  className,
  style,
  dark,
  inline,
  duration = 1300,
}: {
  className?: string
  style?: CSSProperties
  dark?: boolean
  inline?: boolean
  duration?: number
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const intersection = useIntersection(ref, {})

  return (
    <div
      ref={ref}
      className={cx(
        styles.preloader,
        dark && styles['preloader-dark'],
        !inline && 'my-24 mx-auto',
        className,
      )}
      style={style}
    >
      {[2, 3, 4, 1, 2, 3, 0, 1, 2].map((e, i) => (
        <div
          key={i}
          className={cx(
            styles['preloader-cube'],
            'dark:bg-[#ddd]',
            intersection?.isIntersecting && styles['animate'],
            dark && 'bg-[#ddd]',
          )}
          style={{
            animationDelay: `${e * 0.1 - 0.2}s`,
            animationDuration: `${duration}ms`,
          }}
        />
      ))}
    </div>
  )
}
