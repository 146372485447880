import { isFunction } from 'lodash-es'
import { SetStateAction, useCallback, useEffect, useState } from 'react'
import { Subject, filter } from 'rxjs'

const $localStorageUpdate = new Subject<string>()

export const useLocalStorage = <T>(key: string, initialValue?: T) => {
  const getValue = useCallback(() => {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(key)
      if (value) {
        try {
          return JSON.parse(value)
        } catch (err) {
          // ignore
        }
      }
    }
    return typeof initialValue === 'function' ? initialValue() : initialValue
  }, [])
  const [value, setValue] = useState<T>(getValue)

  const set = useCallback(
    (setStateAction: SetStateAction<T>) => {
      setValue(value => {
        const newValue = isFunction(setStateAction)
          ? setStateAction(value)
          : setStateAction

        localStorage.setItem(key, JSON.stringify(newValue))
        $localStorageUpdate.next(key)
        return newValue
      })
    },
    [setValue],
  )

  useEffect(() => {
    const sub = $localStorageUpdate
      .pipe(filter(k => k === key))
      .subscribe(() => {
        setValue(getValue())
      })
    return () => sub.unsubscribe()
  }, [])

  return [value, set] as const
}
