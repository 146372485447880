import { StyledEngineProvider } from '@mui/material/styles'
import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DayjsLocaleEffectApplier } from 'src/components/DayjsLocaleEffectApplier'
import { SWRConfig } from 'swr'
import { MembershipCrownGradient } from './assets/SvgGradients'
import DebugWidget from './components/Admin/DebugWidget'
import ColorModeProvider from './components/Common/ColorModeProvider'
import LoadingPage from './components/LoadingPage'
import { DefaultHelmetTags } from './components/MetaTags'
import { router } from './router'
import { globalSWRConfig } from './services/swr'
import { setVisibleDimensions } from './utils/setVisibleDimensions'

setVisibleDimensions()

function App() {
  const loading = <LoadingPage fullscreen />

  return (
    <SWRConfig value={globalSWRConfig}>
      <StyledEngineProvider>
        <ColorModeProvider>
          <HelmetProvider>
            <DefaultHelmetTags />
            <Suspense fallback={loading}>
              <RouterProvider router={router} fallbackElement={loading} />
              <ToastContainer />
              <DebugWidget />
              <DayjsLocaleEffectApplier />
              <MembershipCrownGradient />
            </Suspense>
          </HelmetProvider>
        </ColorModeProvider>
      </StyledEngineProvider>
    </SWRConfig>
  )
}

export default App
