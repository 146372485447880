import { Client, ClientOptions, createClient } from 'graphql-ws'
import { getWebsocketUrl } from 'src/utils/config'
import { getToken } from 'src/utils/token'

interface RestartableClient extends Client {
  restart(): void
}

// refer: https://the-guild.dev/graphql/ws/recipes#client-usage-with-graceful-restart
const createRestartableClient = (options: ClientOptions): RestartableClient => {
  let restartRequested = false
  let restart = () => {
    restartRequested = true
  }

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: _socket => {
        options.on?.opened?.(_socket)
        const socket = _socket as WebSocket

        restart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            // if the socket is still open for the restart, do the restart
            socket.close(4205, 'Client Restart')
          } else {
            // otherwise the socket might've closed, indicate that you want
            // a restart on the next opened event
            restartRequested = true
          }
        }

        // just in case you were eager to restart
        if (restartRequested) {
          restartRequested = false
          restart()
        }
      },
    },
  })

  return {
    ...client,
    restart: () => restart(),
  }
}

let websocketConnected = false

export const isWebsocketConnected = () => websocketConnected

export const graphqlWsClient = createRestartableClient({
  url: getWebsocketUrl() + '/graphql',
  connectionParams() {
    const token = getToken()
    if (token) {
      return {
        token,
      }
    }
  },
  on: {
    connected() {
      websocketConnected = true
    },
    closed() {
      websocketConnected = false
    },
  },
})

let lastUserId: string | undefined
export const resubscribe = (userId?: string) => {
  if (userId === lastUserId) return
  lastUserId = userId
  graphqlWsClient.restart()
}
