import { mapValues } from 'lodash-es'
import { useMemo } from 'react'
import { TaskSubmitFormValues } from 'src/components/GenerationTask/BaseTaskSubmitForm'
import { GenerationModelType, Task } from 'src/services/generated'
import { useModelVersion } from 'src/stores/model'
import { isXLLora, isXLModel } from './model'

export const VALIDATING = Symbol('Validating')

// prettier-ignore
type ValidationError<P extends Record<string | number | symbol, unknown>> = Record<keyof P, symbol | string[]>
// prettier-ignore
export type ValidateTaskParamsResult<P extends Record<string | number | symbol, unknown>> = ValidationError<P> & { ok: boolean }

interface ValidateTaskParamsContext {
  modelType?: GenerationModelType
  loraTypes?: Record<string, GenerationModelType | undefined>
}

export function validateTaskParams<P extends TaskSubmitFormValues>(
  params: P,
  context: ValidateTaskParamsContext,
): ValidateTaskParamsResult<P> {
  const error = {} as ValidationError<P>

  function add(key: keyof P, reason: string | symbol) {
    if (typeof reason === 'symbol') {
      error[key] = reason
    } else if (typeof error[key] !== 'symbol') {
      error[key] ??= []
      ;(error[key] as string[]).push(reason)
    }
  }

  function result() {
    return { ...error, ok: Object.keys(error).length === 0 }
  }

  /*
   * ---
   */

  if (params.modelId && !context.modelType) {
    add('modelId', VALIDATING)
    return result()
  }

  const usingXLModel = isXLModel(context.modelType)

  const loraIds = Object.keys(params.lora ?? {})

  if (!context.modelType || loraIds.some(id => !context.loraTypes?.[id])) {
    add('lora', VALIDATING)
  } else {
    if (
      loraIds.length > 0 &&
      Object.values(context.loraTypes!).some(
        type => isXLLora(type) !== usingXLModel,
      )
    ) {
      add('lora', 'XL LoRA is only compatible with XL model')
    }
  }

  if (usingXLModel && params.maskMedia) {
    add('maskMedia', 'XL model does not support edit mode')
  }

  if (usingXLModel && params.latentCouple) {
    add('latentCouple', 'XL model does not support composition')
  }

  if (usingXLModel && params.enableTile) {
    add('enableTile', 'XL model does not support ultimate upscale')
  }

  if (
    usingXLModel &&
    (params.controlNets ?? []).length > 0 &&
    !isXLCompatibleControlnets(params.controlNets!)
  ) {
    add('controlNets', 'XL model does not support ControlNet')
  }

  // Be ready for the future support of custom VAE
  // if (usingXLModel && params.vaeModeId) {
  //   add('vaeModeId', 'XL model does not support VAE')
  // }

  // Be ready for the future support of dynamic CFG
  // if (usingXLModel && params.dynthres) {
  //   add('dynthres', 'XL model does not support dynamic CFG')
  // }

  // Be ready for the future support of AnimateDiff
  // if (usingXLModel && params.animateDiff) {
  //   add('animateDiff', 'XL model does not support AnimateDiff')
  // }

  return result()
}

export function useValidateTaskParams(params: TaskSubmitFormValues) {
  const modelVersion = useModelVersion(params.modelId)
  const keys = useMemo(() => Object.keys(params.lora ?? {}), [params.lora])
  const loraVersions = useModelVersion(keys)

  return useMemo(
    () =>
      validateTaskParams(params, {
        modelType: modelVersion?.modelType,
        loraTypes: mapValues(loraVersions, version => version?.modelType),
      }),
    [params, modelVersion, loraVersions],
  )
}

/**
 * Check if a task is an animated image task
 */
export function isAnimatedImageTask(task?: Task) {
  return Boolean(task?.parameters?.animateDiff?.enabled)
}

export const XL_COMPAT_CONTROLNET_METHODS = [
  'canny',
  'depth',
  'openpose',
  'openpose_full',
]

export function isXLCompatibleControlnets(
  controlnets: Required<TaskSubmitFormValues>['controlNets'],
) {
  return controlnets.every(cn => XL_COMPAT_CONTROLNET_METHODS.includes(cn.type))
}
