export const cx = (...args: any[]) => {
  return args
    .filter(i => i)
    .flatMap(i => {
      if (typeof i === 'string')
        return i.split('\n').filter(i => !i.trim().startsWith('//'))

      return Object.entries(i)
        .filter(([, v]) => v)
        .map(([k]) => k)
    })
    .join(' ')
    .split(/\s/)
    .filter(i => i.trim())
    .join(' ')
}
