export const genId = (): string => {
  // generate a random integer that is under Number.MAX_SAFE_INTEGER
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER

  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString()
}

export const genUuid = async () => {
  if (typeof self.crypto.randomUUID === 'function') {
    return self.crypto.randomUUID()
  }
  const uuidv4 = (await import('uuid')).v4
  return uuidv4()
}
