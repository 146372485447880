import { getDeviceId } from '@amplitude/analytics-browser'
import { memoize } from 'lodash-es'
import { Subject, bufferTime } from 'rxjs'
import { config } from './config'
import { genId } from './genId'

const enc = new TextEncoder()

const getKey = memoize(async (key: string) => {
  return await crypto.subtle.importKey(
    'raw',
    enc.encode(key),
    {
      name: 'HMAC',
      hash: 'SHA-256',
    },
    false,
    ['sign', 'verify'],
  )
})

const arrayBufferToHex = (buffer: ArrayBuffer) =>
  [...new Uint8Array(buffer)].map(i => i.toString(16).padStart(2, '0')).join('')

const hmacSha256Hex = async (key: string, data: string) => {
  const buffer = await crypto.subtle.sign(
    'HMAC',
    await getKey(key),
    enc.encode(data),
  )
  return arrayBufferToHex(buffer)
}

const sha256Hex = async (data: ArrayBuffer) => {
  const buffer = await crypto.subtle.digest('SHA-256', data)
  return arrayBufferToHex(buffer)
}

type BatchItem = Record<string, unknown>

export class DataTracker {
  data$: Subject<BatchItem>

  userId?: string
  deviceId?: string

  constructor(
    public ak: string,
    public sk: string,
  ) {
    this.data$ = new Subject<BatchItem>()
    this.data$.pipe(bufferTime(1000, null, 10)).subscribe(async batch => {
      if (batch.length === 0) return
      await this.postBatch(batch)
    })
  }

  async sign(req: Request) {
    const ak = this.ak
    const sk = this.sk
    const timestamp = new Date().toISOString()
    const signatureValidityPeriod = 60
    const authStringPrefix = `pixai-auth-v1/${ak}/${timestamp}/${signatureValidityPeriod}`
    const signingKey = await hmacSha256Hex(sk, authStringPrefix)
    const data = await req.clone().arrayBuffer()
    const hashedRequestPayload = await sha256Hex(data)
    const url = new URL(req.url)
    const canonicalRequest = `${req.method}\n${url.host}${url.pathname}${url.search}\n${hashedRequestPayload}`
    const signature = await hmacSha256Hex(signingKey, canonicalRequest)
    req.headers.set('Authorization', `${authStringPrefix}/${signature}`)
  }

  async postBatch(batch: BatchItem[]) {
    const req = new Request('https://data-getway.pixai.art/v1/batch', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        batch,
      }),
    })
    try {
      await this.sign(req)
      await fetch(req)
    } catch {
      // catches sign error (e.g. in insecure context)
      // if failed to sign, skip fetching
    }
  }

  setUserId(userId?: string) {
    this.userId = userId
  }

  getDeviceId() {
    if (this.deviceId) return this.deviceId
    const did = window.localStorage.getItem('did')
    if (did) return (this.deviceId = did)
    const newDid = getDeviceId() ?? genId()
    window.localStorage.setItem('did', newDid)
    return (this.deviceId = newDid)
  }

  track(event: string, properties: Record<string, unknown>) {
    this.data$.next({
      event,
      properties,
      distinct_id: this.userId,
      timestamp: Date.now(),
      device_id: this.getDeviceId(),
      app_version: config.appVersion,
      platform: 'Web',
    })
  }

  setUserProperties(properties: Record<string, unknown>) {
    this.track('set_user_properties', properties)
  }
}
