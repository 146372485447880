import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { getApiUrl } from './config'

export const TOKEN_KEY = `${getApiUrl()}:token`

export const getToken = () => localStorage.getItem(TOKEN_KEY) || ''

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token)
  window.dispatchEvent(new Event('storage'))
}

export const removeTokenFromCookie = () => {
  Cookies.remove('token')
}

export const setIntercomUserHash = (hash: string) => {
  localStorage.setItem('intercom-user-hash', hash)
  window.dispatchEvent(new Event('storage'))
}

export const unsetIntercomUserHash = () =>
  localStorage.removeItem('intercom-user-hash')

export const getIntercomUserHash = () =>
  localStorage.getItem('intercom-user-hash') || undefined

export const useToken = () => {
  const [token, setToken] = useState(getToken())
  useEffect(() => {
    const listener = () => {
      setToken(getToken())
    }
    window.addEventListener('storage', listener)
    return () => window.removeEventListener('storage', listener)
  }, [setToken])
  return token
}

/**
 * Check token before returning graphql query args
 * Returns input args if token exists
 * Otherwise returns `undefined`
 * @param args
 * @returns
 */
export function useCheckTokenForArgs<T>(args: T) {
  const token = useToken()
  if (!token) return
  return args
}
