import { atom, getDefaultStore } from 'jotai'
import i18n from 'src/i18n/i18next'
import { client } from 'src/services/client'
import { Currency } from 'src/services/generated'
import { getToken } from 'src/utils/token'

export const SUPPORTED_LOCALES = {
  en: { code: 'en', label: 'English', currency: Currency.Usd },
  ja: { code: 'ja', label: '日本語', currency: Currency.Jpy },
  de: { code: 'de', label: 'Deutsch', currency: Currency.Usd },
  fr: { code: 'fr', label: 'Français', currency: Currency.Usd },
  it: { code: 'it', label: 'Italiano', currency: Currency.Usd },
  es: { code: 'es', label: 'Español', currency: Currency.Usd },
  pt: { code: 'pt', label: 'Português', currency: Currency.Usd },
  kr: { code: 'kr', label: '한국어', currency: Currency.Usd },
} as const
export type SupportedLocaleCodes = keyof typeof SUPPORTED_LOCALES
export const DEFAULT_LOCALE: SupportedLocaleCodes = 'en'

const store = getDefaultStore()

export const localeAtom = atom<SupportedLocaleCodes>(DEFAULT_LOCALE)
export const getLocale = () => store.get(localeAtom)
// prettier-ignore
export const setLocale = (value: SupportedLocaleCodes) => store.set(localeAtom, value)

store.sub(localeAtom, async () => {
  const locale = store.get(localeAtom)
  i18n.changeLanguage(locale)

  const token = getToken()
  if (token) await client.setPreferences({ value: { lang: locale } })
})
