import {
  Identify,
  identify as _identify,
  // eslint-disable-next-line no-restricted-imports
  track as _track,
  init,
  setUserId,
} from '@amplitude/analytics-browser'
import * as Sentry from '@sentry/browser'
import debug from 'debug'
import { mapValues, pickBy, snakeCase, update } from 'lodash-es'
import { useEffect, useRef } from 'react'
import { useLocation, useMatches, useParams } from 'react-router-dom'
import { TaskSubmitFormValues } from 'src/components/GenerationTask/BaseTaskSubmitForm'
import { AppRouteObject } from 'src/router'
import { MangaType, RefType, Task } from 'src/services/generated'
import { isValuesGenerativeFill } from 'src/services/helpers/artworkApiParamsPacker'
import { OrderOption } from 'src/services/helpers/orderOptionParams'
import { JsonValue, ValueOf } from 'type-fest'
import { attemptParseJSON } from '.'
import { config } from './config'
import { DataTracker } from './dataTracker'
import { genUuid } from './genId'

const log = debug('pixai:tracker')

declare global {
  interface Window {
    gtag?(...args: any): any
  }
}

init(config.amplitudeApiKey, undefined, {
  serverUrl: config.amplitudeServerUrl,
  defaultTracking: {
    pageViews: {
      trackOn: 'attribution',
    },
  },
  appVersion: config.appVersion,
})

const dataTracker = new DataTracker(config.data.ak, config.data.sk)

export const trackerSetUserId = (userId: string) => {
  setUserId(userId)
  dataTracker.setUserId(userId)
  Sentry.setUser({
    id: userId,
  })

  window.gtag?.('config', 'G-NJREWETS8K', {
    user_id: userId,
  })
}

export const trackerLogout = () => {
  Sentry.setUser(null)
  setUserId(undefined)
  dataTracker.setUserId(undefined)
  window.gtag?.('config', 'G-NJREWETS8K', {
    user_id: null,
  })
}

export const setUserProperties = async (properties: Record<string, any>) => {
  const identifyEvent = new Identify()
  for (const [k, v] of Object.entries(properties)) {
    v !== undefined && identifyEvent.set(k, v)
  }

  // Identify will flood lots of errors unless apiKey is set
  if (config.amplitudeApiKey) {
    _identify(identifyEvent)
  }
  dataTracker.setUserProperties(properties)
}

let lastEvent: string
const STORAGE_KEY_EVENT_GROUP = 'tracker:event-group'
let eventGroups: Record<string, { value: JsonValue; persistent?: boolean }> =
  attemptParseJSON(localStorage.getItem(STORAGE_KEY_EVENT_GROUP)) || {}

function readEventGroupValues() {
  return mapValues(eventGroups, value => value.value)
}

export type TrackDestination = 'amplitude' | 'getway' | 'gtag'

export interface TrackOptions {
  destinations?: TrackDestination[]
}

export const track = (
  event: string,
  attrs?: any,
  options: TrackOptions = {
    destinations: ['amplitude', 'getway', 'gtag'],
  },
) => {
  lastEvent = event
  attrs = { ...attrs, ...readEventGroupValues() }
  log('event: %s attrs: %o', event, attrs)

  if (config.isDev) return

  if (options.destinations?.includes('getway')) dataTracker.track(event, attrs)
  if (options.destinations?.includes('amplitude')) _track(event, attrs)
  if (options.destinations?.includes('gtag') && config.isProd)
    window.gtag?.('event', event)
}

function writeEventGroups() {
  localStorage.setItem(STORAGE_KEY_EVENT_GROUP, JSON.stringify(eventGroups))
}

/**
 * Start an event group (marks for a series of events)
 *
 * @param name               - The name of an event group.
 * @param value              - The value of the event group.
 * @param options
 * @param options.persistent - Whether not to remove the event group after page reload.
 *                             Event groups are auto-removed during page reloads.
 *                             Set to `true` to keep the event group.
 *                             Persistent event groups also remain after `endEventGroup(true)`.
 *                             You will have to remove them manually.
 */
export function startEventGroup(
  name: string,
  value?: JsonValue,
  options?: { persistent?: boolean },
) {
  eventGroups[name] = { value: value ?? lastEvent, ...options }
  writeEventGroups()
}

export enum UpdateEventGroupAction {
  Increment = 'Increment',
}

export function updateEventGroup(name: string, action: UpdateEventGroupAction) {
  switch (action) {
    case UpdateEventGroupAction.Increment:
      update(eventGroups, name, prev => ({
        ...prev,
        value: (typeof prev?.value === 'number' ? prev.value : 0) + 1,
      }))
      break
  }
  writeEventGroups()
}

/**
 * Close an event group
 *
 * @param name - The name of an event group.
 *               `true` to close all groups that are not persistent.
 */
export function endEventGroup(name: string | true) {
  if (name === true) {
    eventGroups = pickBy(eventGroups, value => value.persistent)
  } else {
    delete eventGroups[name]
  }
  writeEventGroups()
}

const getRouteName = (matches?: any[]) => {
  return matches?.at(-1)?.handle?.name
}

let routes: AppRouteObject[]
export const setRoutes = (value: AppRouteObject[]) => {
  routes = value
}

const getPathPatternFromMatches = (matches?: { id: string }[]) => {
  const lastMatch = matches?.at(-1)
  if (!lastMatch) return
  const indexes = lastMatch.id.split('-').map(i => +i)
  let pathPattern = ''
  let routeObjects = routes
  for (const index of indexes) {
    const routeObject = routeObjects[index]
    if (!routeObject) return
    const pathSpan = (routeObject.path || '').replaceAll('*', '')
    pathPattern += pathSpan
    routeObjects = routeObject.children || []
  }
  return pathPattern
}

export const useTrackPageView = () => {
  const location = useLocation()
  const matches = useMatches()
  const params = useParams()

  const lastPathnameRef = useRef<string>()
  const lastMatchesRef = useRef<typeof matches>()

  useEffect(() => {
    const lastPathname = lastPathnameRef.current
    const lastMatches = lastMatchesRef.current

    if (location.pathname === lastPathname) return

    const pageViewProperties = {
      pathname: location.pathname,
      routeName: getRouteName(matches),
      pathPattern: getPathPatternFromMatches(matches),
      fromPathname: lastPathname,
      fromRouteName: getRouteName(lastMatches),
      fromPathPattern: getPathPatternFromMatches(lastMatches),
      ...params,
    }

    track('page_view', pageViewProperties)

    lastPathnameRef.current = location.pathname
    lastMatchesRef.current = matches
  }, [location.pathname])
}

export const trackUserPreference = ({
  type,
  action,
  taskId,
  currentTaskId,
  taskParams,
  publishParams,
  otherParams,
}: {
  type:
    | 'variation'
    | 'upscale'
    | 'enlarge'
    | 'publish'
    | 'download'
    | 'click'
    | 'fav'
    | 'delete'
  action?: 'cancel' | 'click' | 'confirm' | 'success'
  taskId?: string
  /**
   * currentTaskId: submitted task's id
   */
  currentTaskId?: string
  taskParams?: TaskSubmitFormValues | Task['parameters']
  // only avaliable when type === 'publish'
  publishParams?: {
    isPrivate?: boolean | null
    hidePrompts?: boolean | null
    tags?: string[] | null
    title?: string | null
    extra?: {
      description?: string
    }
    hasFav?: boolean
  }
  otherParams?: {
    scene?: string
    mediaIdx?: number
    mediaId?: string
    duration?: number
  }
}) => {
  const { scene, mediaIdx, mediaId, duration } = otherParams ?? {}

  const getTaskParams = (t?: TaskSubmitFormValues | Task['parameters']) => {
    const useI2i =
      t.mediaId != null || // Task
      t.baseImageMedia != null || // Form Values
      t.fileToUpload != null // Form Values

    const hasUpscale = (t.upscale ?? 0) > 1
    return {
      batchSize: t.batchSize,
      useI2i,
      useLoRA: t.lora != null && Object.keys(t.lora).length > 0,
      useControlNet:
        !useI2i && t.controlNets != null && t.controlNets.length > 0,
      useHires:
        !useI2i &&
        hasUpscale &&
        (t.upscaleDenoisingStrength != null || t.upscaleDenoisingSteps != null),
      useUpscale: useI2i && t.enlarge > 1,
      useImageEnhance: useI2i && hasUpscale,
      useGenerativeFill: isValuesGenerativeFill(t),
      useAnimate: Boolean(t.animateDiff?.enabled),
      modelName: t.model,
      modelId: t.modelId,
    }
  }

  const getPublishParams = (p: typeof publishParams) => ({
    isPrivate: p?.isPrivate ?? false,
    hidePrompts: p?.hidePrompts ?? false,
    hasTitle: (p?.title != null && p.title.length > 0) ?? false,
    hasTag: (p?.tags ?? []).length > 0,
    hasDescription:
      p?.extra?.description != null && p?.extra.description.length > 0,
  })

  return track(
    'user_preference',
    {
      // normal attrs,
      type,
      scene,
      taskId,
      currentTaskId,
      mediaIdx,
      mediaId,
      action,
      duration,
      // task params,
      ...getTaskParams(taskParams),
      // publish params,
      ...getPublishParams(publishParams),
    },
    {
      destinations: ['getway'],
    },
  )
}

/**
 * Tracking events for recommendations
 */

/**
 * Should be reset at first load/page switching/feed switching
 */
let recoTrackingFeedUuid: string | null = null

type TrackImageForRecArgs =
  | {
      artworkId: string
      /**
       * Index of current artwork
       */
      index: number
      totalCols: number
      currentLikeCount: number

      scene: 'artwork_feed' | 'model_feed'
      feed: ValueOf<typeof OrderOption>
      type?: RefType | MangaType
      likeFrom?: 'grid' | 'detail'
    }
  | {
      fromFeed: string
      toFeed: string
    }

type TrackImageForRecOpts = {
  regenFeedUuid?: boolean
}

/**
 * Specialized tracking function for image recommendations
 * related actions
 */
export async function trackImageForRec(
  eventName: string,
  eventArgs: TrackImageForRecArgs,
  opts?: TrackImageForRecOpts,
) {
  try {
    if (recoTrackingFeedUuid == null || opts?.regenFeedUuid) {
      recoTrackingFeedUuid = await genUuid()
    }
  } catch (e) {
    // UUID not available
    return
  }

  return track(
    eventName,
    {
      // Convert property names to snake_case
      ...Object.fromEntries(
        Object.entries(eventArgs).map(([k, v]) => [snakeCase(k), v]),
      ),
      feed_session: recoTrackingFeedUuid,
    },
    {
      destinations: ['getway'],
    },
  )
}
