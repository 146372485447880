import { Breakpoint, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const useIsMobile = (
  key: Breakpoint = 'md',
  dir: 'down' | 'up' = 'down',
) => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints[dir](key))
}

export const useIsLg = () => {
  // matches tailwind lg
  return useMediaQuery('(min-width: 1024px)')
}

export const useIsXsMobile = () => {
  return useMediaQuery('(max-width:400px)')
}
