import { cloneDeep, set } from 'lodash-es'
import { useEffect } from 'react'
import { useCheckTokenForArgs } from 'src/utils/token'
import { trackerSetUserId } from 'src/utils/tracker'
import { getNodesFromConnection } from '../utils/graphql'
import { $newNotification, $taskUpdated } from './events'
import { Manga, MangaType, UserSettingType } from './generated'
import { AnimatedComicTask } from './helpers/animatedComic'
import { createSWRForGQL } from './swr'
import { isWebsocketConnected, resubscribe } from './websocket'

export const [useArtworks] = createSWRForGQL('listArtworks', {
  reduce: r => r.artworks,
})

export const [useArtwork, mutateArtwork] = createSWRForGQL('getArtwork', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.artwork,
})

export const [useArtworkWithLoras, mutateArtworkWithLoras] = createSWRForGQL(
  'getArtworkWithLoras',
  {
    transform: (id: string) => (id ? { id } : undefined),
    reduce: r => r.artwork,
  },
)

export const [useArtworkThemeById, mutateArtworkThemeById] = createSWRForGQL(
  'getArtworkThemeById',
  {
    transform: (id: string) => (id ? { id } : undefined),
    reduce: r => r.artworkTheme,
  },
)

export const [useUserArtworkLikedCount] = createSWRForGQL(
  'getUserArtworkLikedCount',
  {
    transform: (userId: string) => (userId ? { userId } : undefined),
    reduce: r => r.user?.artworkLikedCount,
  },
)

export const [useUserLikedArtworks] = createSWRForGQL('listUserLikedArtworks', {
  reduce: r => r.user?.likedArtworks,
})

export const [useUserLikedMangaList] = createSWRForGQL(
  'listUserLikedMangaList',
  { reduce: r => r.user?.likedMangaList },
)

export const [useMessage] = createSWRForGQL('getMessage', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.message,
})

export const [useMessages] = createSWRForGQL('listMessages', {
  reduce: r => r.messages,
})

export const [useMessageCount] = createSWRForGQL('getMessageCount', {
  transform: (topicId: string) => (topicId ? { topicId } : undefined),
  reduce: r => r.messages.totalCount,
})

export const [useReviewSummary] = createSWRForGQL('getReviewSummary', {
  transform: (itemId: string) => (itemId ? { itemId } : undefined),
  reduce: r => r.reviewSummary,
})

export const [useManga] = createSWRForGQL('getManga', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.manga,
})

export const [useMangaChapter] = createSWRForGQL('getMangaChapter', {
  transform: ({
    mangaId,
    chapterId,
    index,
  }: {
    mangaId?: string
    chapterId?: string
    index?: number
  }) =>
    mangaId && (chapterId || index != null)
      ? { mangaId, chapterId, index }
      : undefined,
  reduce: r => r.manga?.chapter,
})

/**
 * Animated Comic
 */

export interface AnimatedComicManga extends Manga {
  type: MangaType.AnimatedComic
  chapter: Manga['chapter'] & { content: AnimatedComicTask }
  // The server does not process this field
  extra: never
}

export const [useAnimatedComic] = createSWRForGQL('getAnimatedComic', {
  transform: (mangaId: string) => (mangaId ? { mangaId } : undefined),
  reduce: r => r.manga as AnimatedComicManga | undefined,
})

/**
 * ---
 */

const [_useUserInfo, mutateUserInfo] = createSWRForGQL(
  'getUserInfo',
  { transform: () => ({}), reduce: r => r.me },
  { shouldRetryOnError: false },
)
export { mutateUserInfo }
export const useUserInfo: typeof _useUserInfo = (param, ...args) => {
  const res = _useUserInfo(useCheckTokenForArgs(param), ...args)

  useEffect(() => {
    if (res.data) {
      trackerSetUserId(res.data.id)
    }
    resubscribe(res.data?.id)
  }, [res.data?.id])

  // directly returning won't error but this will, oh well, not like that i care
  return res as any
}

export const [useUserRoles] = createSWRForGQL('getUserRoles', {
  reduce: r => getNodesFromConnection(r?.user?.roles),
})

export const [useMedia] = createSWRForGQL(
  'getMedia',
  {
    transform: (id: string) => (id ? { id } : undefined),
    reduce: r => r.media,
  },
  {
    // Media data should be stable, so no need to revalidate all the time
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  },
)

export const [useTag] = createSWRForGQL('getTag', {
  reduce: r => r.tag,
})

export const [useTags] = createSWRForGQL('listTags', {
  reduce: r => r.tags,
})

export const [useAllTags] = createSWRForGQL('listTags', {
  transform: () => ({ first: 100 }),
  reduce: r => getNodesFromConnection(r.tags),
})

export const [useSuggestedTags] = createSWRForGQL('listSuggestedTags', {
  reduce: r => r.suggestedTags,
})

export const [useUser] = createSWRForGQL('getUserInfoById', {
  transform: (userId: string) => (userId ? { userId } : undefined),
  reduce: r => r.user,
})

export const [useUserDetail] = createSWRForGQL('getUserDetailById', {
  transform: (userId: string) => (userId ? { userId } : undefined),
  reduce: r => r.user,
})

export const [useUserByUsername] = createSWRForGQL('getUserInfoByUsername', {
  transform: (username: string) => (username ? { username } : undefined),
  reduce: r => r.user,
})

const [_useMyTasks] = createSWRForGQL(
  'listMyTasks',
  { reduce: r => r.me?.tasks },
  {
    refreshInterval() {
      return isWebsocketConnected() ? 0 : 5e3
    },
  },
)
export const useMyTasks = (...args: Parameters<typeof _useMyTasks>) => {
  const result = _useMyTasks(...args)

  useEffect(() => {
    const subscription = $taskUpdated.subscribe(task => {
      result.mutate(data => {
        if (!data) return data
        const index = data?.edges?.findIndex(i => i?.node?.id === task.id)
        if (index == null || index === -1) {
          // force invalidate task list if not found
          setTimeout(() => result.mutate())
          return data
        }
        return set(cloneDeep(data), ['edges', index, 'node'], task)
      }, false)
    })

    return () => subscription.unsubscribe()
  }, [])

  return result
}

export const [useUserTasks] = createSWRForGQL('listUserTasks', {
  reduce: r => r.user?.tasks,
})

export const [useTask, mutateTask] = createSWRForGQL(
  'getTaskById',
  {
    transform: (id: string) => (id ? { id } : undefined),
    reduce: r => r.task,
  },
  {
    refreshInterval() {
      return isWebsocketConnected() ? 0 : 5e3
    },
  },
)

export const [useTaskOfArtwork] = createSWRForGQL('getTaskOfArtwork', {
  transform: (artworkId: string) => (artworkId ? { artworkId } : undefined),
  reduce: r => r.artwork?.task,
})

export const [useUserProfiles] = createSWRForGQL('getUserProfiles', {
  transform: (userId: string) => (userId ? { userId } : undefined),
  reduce: r => r.user,
})

export const [useMyExternalProfiles] = createSWRForGQL(
  'getMyExternalProfiles',
  {
    transform: () => ({}),
    reduce: r => r.me?.externalProfiles,
  },
)

export const [useUserWithExternalProfiles] = createSWRForGQL(
  'getUserWithExternalProfiles',
  {
    transform: (userId: string) => (userId ? { userId } : undefined),
    reduce: r => r.user,
  },
)

export const [usePublicDynamicConfig] = createSWRForGQL(
  'getPublicDynamicConfig',
  {
    transform: (key: string) => (key ? { key } : undefined),
    reduce: r => r.publicDynamicConfig || undefined,
  },
)

export const [useDynamicConfig] = createSWRForGQL('getPublicDynamicConfig', {
  transform: (key: string) => (key ? { key } : undefined),
  reduce: r => r.publicDynamicConfig || undefined,
})

export const [usePricing] = createSWRForGQL('pricingTask', {
  transform: (parameters: any) => (parameters ? { parameters } : undefined),
  reduce: r => r.pricingTask,
})

export const [useUserQuota] = createSWRForGQL('getUserQuota', {
  transform: (userId: string) => (userId ? { userId } : undefined),
  reduce: r => r.user?.quotaAmount,
})

const [_useMyQuota, mutateMyQuota] = createSWRForGQL('getMyQuota', {
  transform: () => ({}),
  reduce: r => r.me?.quotaAmount ?? 0,
})
export { mutateMyQuota }
export const useMyQuota: typeof _useMyQuota = (param, ...args) =>
  _useMyQuota(useCheckTokenForArgs(param), ...args)

export const [useMeWithQuotaForCurrency] = createSWRForGQL(
  'getMeWithQuotaForCurrency',
  { reduce: r => r.me },
)

export const [useTaskStatistics] = createSWRForGQL('getTaskStatistics', {
  reduce: r => r.taskStatistics,
})
export const [useTaskPriorityChannels] = createSWRForGQL(
  'getTaskPriorityChannels',
  {
    transform: (model: string) => (model ? { model } : undefined),
    reduce: r => r.taskPriorityChannels,
  },
)

const [_useTaskModelList] = createSWRForGQL('getTaskModelList', {
  transform: () => ({}),
  reduce: r => r.taskModelList,
})
/**
 * @deprecated
 * Deprecated. Use modelIds from dynamic config instead.
 * Don't import it in components other than TrainLora.
 */
export const useTaskModelList = _useTaskModelList

const [_useDailyClaimAvailable] = createSWRForGQL('getDailyClaimAvailable', {
  reduce: r => r.me,
})
export const useDailyClaimAvailable = () => {
  const { data: me } = useUserInfo()
  return _useDailyClaimAvailable(me ? {} : undefined)
}

export const [useUserQuotaLogs] = createSWRForGQL('listUserQuotaLogs', {
  reduce: r => r.user?.quotaLogs,
})

export const [useMyQuotaLogs] = createSWRForGQL('listMyQuotaLogs', {
  reduce: r => r.me?.quotaLogs,
})

export const [useMyReferralInfo] = createSWRForGQL('getMyReferralInfo', {
  transform: () => ({}),
  reduce: r => r.me?.referralInfo,
})

export const [useMyMFAList, mutateMyMFAList] = createSWRForGQL(
  'listMyMFAList',
  { reduce: r => r.me?.mfaList },
)

export const [useMyNotifications, mutateMyNotifications] = createSWRForGQL(
  'listMyNotifications',
  { reduce: r => r.me?.notifications },
)

export interface UserPreferences {
  showNsfw?: boolean
  blurNsfw?: boolean
  safeSearch?: boolean
  promoEmail?: boolean
}

const [_useMyPreferences] = createSWRForGQL(
  'getMyPreferences',
  {
    transform: () => ({}),
    reduce: r => r.me?.preferences,
  },
  { dedupingInterval: 5 * 60e3 },
)
export const useMyPreferences: typeof _useMyPreferences = (param, ...args) =>
  _useMyPreferences(useCheckTokenForArgs(param), ...args)

export const [useMySetting] = createSWRForGQL('getMySetting', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.setting,
})
export const [useMySettings] = createSWRForGQL('getMySettings', {
  reduce: r => r.me?.settings,
})
export const [useMySettingLimit] = createSWRForGQL('getMySettingLimit', {
  transform: (type: UserSettingType) => (type ? { type } : undefined),
  reduce: r => r.me?.settingLimit,
})

export const [useMySessions] = createSWRForGQL('listMySessions', {
  reduce: r => r.me?.sessions,
})

export const [useMyAccessTokens, mutateMyAccessTokens] = createSWRForGQL(
  'listMyAccessTokens',
  { reduce: r => r.me?.accessTokens },
)
export const [useMyWebhooks, mutateMyWebhooks] = createSWRForGQL(
  'listMyWebhooks',
  { reduce: r => r.me?.webhooks },
)

export const [useGenerationModel, mutateGenerationModel] = createSWRForGQL(
  'getGenerationModel',
  {
    transform: (id: string) => (id ? { id } : undefined),
    reduce: r => r.generationModel,
  },
)

export const [useUserBookmarkedGenerationModels] = createSWRForGQL(
  'listUserBookmarkedGenerationModels',
  { reduce: r => r.user?.bookmarkedGenerationModels },
)

export const [useGenerationModelByVersionId] = createSWRForGQL(
  'getGenerationModelByVersionId',
  {
    transform: (id: string) => (id ? { id } : undefined),
    // `|| undefined` here to convert null to undefined,
    // since null can't be destructured
    reduce: r => r.generationModelVersion || undefined,
  },
)

export const [useGenerationModels] = createSWRForGQL('listGenerationModels', {
  reduce: r => r.generationModels,
})

export const [useGenerationModelVersions] = createSWRForGQL(
  'listGenerationModelVersions',
  { reduce: r => r.generationModelVersions },
)

export const [useUserLikedGenerationModels] = createSWRForGQL(
  'listUserLikedGenerationModel',
  { reduce: r => r.user?.likedGenerationModelList },
)

const [_useMyUnreadNotiCounts] = createSWRForGQL('getMyUnreadNotiCount', {
  reduce: r => r.me?.unreadNotificationCounts,
})
export const useMyUnreadNotiCounts: typeof _useMyUnreadNotiCounts = (
  param,
  ...args
) => {
  const res = _useMyUnreadNotiCounts(useCheckTokenForArgs(param), ...args)

  useEffect(() => {
    const subscription = $newNotification.subscribe(noti => {
      res.mutate(data => {
        if (!data) return data
        if (noti.unread) {
          // force invalidate unread noti count once any unread new noti received
          setTimeout(() => res.mutate())
          return data
        }
        return data
      }, false)
    })

    return () => subscription.unsubscribe()
  }, [])

  return res as any
}

export const [useMyBookmarkItems] = createSWRForGQL('listMyBookmarkItems', {
  reduce: r => r.me?.defaultBookmark?.items,
})
export const [useBookmarks] = createSWRForGQL('listMyBookmarks', {
  reduce: r => r.user?.bookmarks,
})
export const [useBookmarkItems] = createSWRForGQL('listBookmarkItems', {
  reduce: r => r.bookmark?.items,
})
export const [useBookmark] = createSWRForGQL('getBookmark', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.bookmark,
})

const [_useMyBookmarkedGenerationModels] = createSWRForGQL(
  'listMyBookmarkedGenerationModels',
  { reduce: r => r.me?.bookmarkedGenerationModels },
)
export const useMyBookmarkedGenerationModels: typeof _useMyBookmarkedGenerationModels =
  (param, ...args) =>
    _useMyBookmarkedGenerationModels(useCheckTokenForArgs(param), ...args)

export const [useAllPaymentItems] = createSWRForGQL('getAllPaymentItems', {
  transform: () => ({}),
  reduce: r => r.paymentItems,
})

export const [useOrderById] = createSWRForGQL(
  'getOrderById',
  {
    transform: (orderId: string) => (orderId ? { orderId } : undefined),
    reduce: r => r.order,
  },
  { refreshInterval: 5000 },
)

const [_useMyMembership, mutateMyMembership] = createSWRForGQL(
  'getMyMembership',
  {
    transform: (skip: boolean) => (skip ? undefined : {}),
    reduce: r => r.me,
  },
)
export { mutateMyMembership }
export const useMyMembership: typeof _useMyMembership = (param, ...args) =>
  _useMyMembership(useCheckTokenForArgs(param), ...args)

export const [useGenerationModelTotalCount] = createSWRForGQL(
  'getPrivateGenerationModelTotalCount',
  { reduce: r => r.generationModels.totalCount },
)

export const [useMyShareCreditInfo] = createSWRForGQL('getMyShareCreditInfo', {
  transform: (skip: boolean) => (skip ? undefined : {}),
  reduce: r => r.me?.shareCreditInfo,
})

export const [useNotifications] = createSWRForGQL('listNotifications', {
  reduce: r => r.notifications,
})

export const [useNotification] = createSWRForGQL('getNotification', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.notification,
})

export const [useContest] = createSWRForGQL('getContest', {
  transform: (id: string) => (id ? { id } : undefined),
  reduce: r => r.contest,
})
export const [useContests] = createSWRForGQL('listContests', {
  reduce: r => r.contests,
})

/**
 * ==== Quest ==================================================================
 */

const [_useQuests] = createSWRForGQL('listQuests', {
  reduce: r => ({
    nodes: getNodesFromConnection(r.quests),
    pageInfo: r.quests?.pageInfo,
  }),
})
export const useQuests: typeof _useQuests = (vars, opts) =>
  _useQuests(useCheckTokenForArgs(vars), opts)

export const [useQuest] = createSWRForGQL('getQuest', {
  reduce: r => r.quest,
})

/**
 * =============================================================================
 */

const [_useMyAppGiftInfo] = createSWRForGQL('getMyAppGiftInfo', {
  reduce: r => r.me?.appGiftInfo,
})
export const useMyAppGiftInfo: typeof _useMyAppGiftInfo = (param, ...args) =>
  _useMyAppGiftInfo(useCheckTokenForArgs(param), ...args)

export const [useUsers] = createSWRForGQL('listUsers', {
  reduce: r => r.users,
})
