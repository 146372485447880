// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs'
import Duration from 'dayjs/plugin/duration'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import RelativeTime from 'dayjs/plugin/relativeTime'
import Utc from 'dayjs/plugin/utc'

dayjs.extend(LocalizedFormat)
dayjs.extend(RelativeTime)
dayjs.extend(Duration)
dayjs.extend(Utc)

export default dayjs

export const formatTime = (time: dayjs.ConfigType) => {
  if (!time) return
  const t = dayjs(time)
  if (t.isAfter(dayjs().subtract(1, 'd'))) return t.fromNow()
  return t.format('lll')
}
