import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import {
  setLocale,
  SUPPORTED_LOCALES,
  SupportedLocaleCodes,
} from 'src/stores/intl'
import { flattenObject } from 'src/utils'
import { resources } from './resources'

const loadPath = `https://cdn.simplelocalize.io/f3353c72ee48433ba07d0cc8b0973612/_latest/{{lng}}/{{ns}}`

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    backend: {
      backends: [HttpBackend],
      backendOptions: [{ loadPath }],
    },
    detection: {
      order: ['querystring', 'localStorage', 'navigator', 'htmlTag', 'path'],
      caches: ['localStorage'],
      lookupQuerystring: 'lang',
    },
    ns: [
      'action',
      'artwork',
      'auth',
      'comment',
      'common',
      'generation',
      'growth',
      'manga',
      'market',
      'membership',
      'user',
    ],
    defaultNS: 'common',
    supportedLngs: Object.keys(SUPPORTED_LOCALES),
    fallbackLng: {
      default: ['en'],
    },
    returnEmptyString: false,
    nonExplicitSupportedLngs: true,
    debug: import.meta.env.DEV,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: 'languageChanged',
    },
  })

i18n.on('initialized', () => {
  setLocale(i18n.resolvedLanguage as SupportedLocaleCodes)
})

// add bundled data as fallback value
// TODO: maybe a better way to achieve this
i18n.on('loaded', lngs => {
  for (const [lng, namespaces] of Object.entries(lngs)) {
    for (const ns in namespaces) {
      const res = (resources as any)?.[lng]?.[ns]
      if (res) {
        i18n.addResourceBundle(lng, ns, flattenObject(res), true, false)
      }
    }
  }
})

// i18n.on('languageChanged', lng => {
//   // update dayjs locale here
//   // FIXME: doesn't seem to work
//   dayjs.locale(lng)
// })

// set initial lang attribute on load (once)
document.documentElement.setAttribute('lang', i18n.resolvedLanguage)

// update lang attribute on everytime language changes
i18n.on('languageChanged', lng => {
  document.documentElement.setAttribute('lang', i18n.resolvedLanguage)
})

// DEBUG only
if (import.meta.env.DEV) {
  // @ts-ignore
  window.i18n = i18n
}

export { i18n }
export default i18n
