import { pick } from 'lodash-es'
import { TaskSubmitFormValues } from 'src/components/GenerationTask/BaseTaskSubmitForm'
import { type Tag, type UpsertArtworkInput } from 'src/services/generated'

export const normalizeTags = (tags?: (string | Tag)[]) => {
  return tags?.map(tag => (typeof tag === 'string' ? tag : tag.name))
}

const taskFieldsToTrack = [
  'autoPublish',
  'samplingSteps',
  'cfgScale',
  'hidePrompts',
  'isPrivate',
  'priority',
  'modelId',
  'batchSize',
  'taskId',
  'activityId',
]

const getTrackAttributes = (values: any) => {
  return {
    ...pick(values, taskFieldsToTrack),
    ...values?.extra,
    size: `${values.width ?? 0}x${values.height ?? 0}`,
  }
}

export const getUpsertArtworkTrackAttrs = (values: any) => {
  return {
    ...getTrackAttributes(values),
    isEdit: !!values?.artworkId,
    hasTitle: !!values?.title?.trim(),
  }
}

export const getCreateTaskTrackAttrs = (values: any) => {
  return {
    ...getTrackAttributes(values),
    hasBaseImage: values?.baseImageMedia != null,
    hasBaseInpaint: values?.maskMedia != null,
    isHomepage: !!values?.isHomepage,
    hasBaseControl: values.controlNets != null && values.controlNets.length > 0,
    // ensure lora object is valid
    hasBaseLoRA: values?.lora != null && Object.keys(values.lora).length > 0,
    hasBaseCompo:
      values?.latentCouple != null &&
      Object.keys(values.latentCouple).length > 0,
    hasBaseHires: values?.upscaleDenoisingStrength != null,
    hasEnableTile: !!values?.enableTile,
    hasEnableADetailer: !!values?.enableADetailer,
    hasCustomizedClipSkip: values.clipSkip != null && values.clipSkip !== 2,
    vaeModelId: values?.vaeModelId,

    upscaleMethod:
      (values.enlarge ?? 0) >= 1 && values.enlargeModel
        ? 'upscale'
        : (values.upscale ?? 0) >= 1
          ? 'enhance'
          : 'none',

    hasBaseFill: isValuesGenerativeFill(values),
    // FIXME: avoid using untyped fields
    generativeFillType: isValuesGenerativeFill(values)
      ? values.generativeFillType // 'task' | 'upload'
      : undefined,
    fillEditorType: isValuesGenerativeFill(values)
      ? values.fillEditorType // 'inpaint' | 'outpaint'
      : undefined,
  }
}

const artworkBaseInfoFields = [
  'authorName',
  'prompts',
  'hidePrompts',
  'isPrivate',
  'title',
  'extra',
  'rootThemeId',
]

const getArtworkBaseInfoInputValues = (values: any): UpsertArtworkInput => {
  const { tags, media } = values

  return {
    ...pick(values, artworkBaseInfoFields),
    mediaId: media?.id,
    tags: normalizeTags(tags),
  }
}

export const getUpsertArtworkInput = (values: any) => {
  return getArtworkBaseInfoInputValues(values)
}

const taskParameterFields = [
  'prompts',
  'negativePrompts',
  'samplingSteps',
  'samplingMethod',
  'cfgScale',
  'seed',
  'autoPublish',
  'extra',
  'priority',
  'width',
  'height',
  'clipSkip',
  'modelId',
]

const i2iTaskParameters = ['strength']
const enlargeParameters = ['enlarge', 'enlargeModel']
const upscaleParameters = [
  'upscale',
  'upscaleDenoisingStrength',
  'upscaleDenoisingSteps',
  'upscaleSampler',
  'enableTile',
]
const loraTaskParameters = ['lora']
const latentCoupleParameters = ['latentCouple']

export const getCreateTaskParameters = (values: TaskSubmitFormValues) => {
  const {
    width,
    height,
    baseImageMedia,
    maskMedia,
    fileToUpload,
    lora,
    latentCouple,
    batchSize,
    enlarge,
    enlargeModel,
  } = values

  const isI2i = isValuesI2i(values)
  const isControlNet = isValuesControlNet(values)
  const isGenerativeFill = isValuesGenerativeFill(values)
  const hasMaskMedia = maskMedia != null

  // ensure lora object is valid
  const isLora = lora != null && Object.keys(lora).length > 0
  const isLatentCouple =
    latentCouple != null && Object.keys(latentCouple).length > 0
  const isBatchGen = (batchSize ?? 1) > 1
  const isEnlarge = (enlarge ?? 0) >= 1 && enlargeModel

  const controlNets = isControlNet ? values.controlNets : []

  const hasVaeModel = !!values.vaeModelId

  const params = {
    ...getUpsertArtworkInput(values),
    ...pick(values, [
      ...taskParameterFields,

      // add params by different task traits
      ...(isI2i ? i2iTaskParameters : []),
      ...(isLora ? loraTaskParameters : []),
      ...(isLatentCouple ? latentCoupleParameters : []),
      // upscale / enlarge params are mutually exclusive; enlarge takes precedence
      ...(isEnlarge ? enlargeParameters : upscaleParameters),
      ...(isBatchGen ? ['batchSize'] : []),
      // mask media is incompatible with enableADetailer
      ...(!hasMaskMedia ? ['enableADetailer'] : []),
      // remove vaeModelId field if vaeModelId is invalid (prevent `vaeModelId: ""`)
      ...(hasVaeModel ? ['vaeModelId'] : []),
    ]),
    // use size from preset to override size if not i2i / generative fill
    ...(isI2i || isGenerativeFill ? {} : { width, height }),
    mediaId: isI2i ? baseImageMedia?.id ?? 'STUB' : undefined, // STUB is needed for price calc
    maskMediaId: maskMedia?.id,
    controlNets,
  }

  return {
    ...params,
    ...(!params.width || !params.height ? { width: 512, height: 512 } : {}), // ensure width and height are valid
  }
}

// const addReferenceMediaToControlNet = (
//   controlNets: TaskSubmitFormValues['controlNets'],
//   baseImageMedia: TaskSubmitFormValues['baseImageMedia'],
// ) => {
//   if (!controlNets) return controlNets

//   const idx = controlNets.findIndex(cn => cn.type === 'reference_only')
//   if (idx < 0) return controlNets

//   const entry = controlNets[idx]
//   const newCns = Array.from(controlNets)

//   if (!baseImageMedia) {
//     newCns.splice(idx, 1) // remove invalid controlnet without reference media
//   } else {
//     newCns.splice(idx, 1, { ...entry, mediaId: baseImageMedia.id })
//   }
//   return newCns
// }

export const isValuesI2i = (values: TaskSubmitFormValues) => {
  return values.baseImageMedia != null || values.fileToUpload != null
}

export const isValuesControlNet = (values: TaskSubmitFormValues) => {
  if (values.controlNets?.some(cn => cn.type === 'inpaint')) return true
  return (
    values.baseImageMedia == null &&
    values.fileToUpload == null &&
    values.controlNets != null &&
    values.controlNets.length > 0
  )
}

type Dim = {
  width?: number
  height?: number
}
const DEFAULT_DIM = { width: 512, height: 768 } // TODO: don't hardcode these values
const DIMS = [512, 768, 1024]
/**
 * replace disabled combination: 512x1024 1024x512
 * @param param0
 * @returns
 */
const replaceDisabledSizes = ({ width, height }: Dim) => {
  if (width === 1024 && height === 512) return { width: 768, height: 512 }
  if (width === 512 && height === 1024) return { width: 512, height: 768 }
  return { width, height }
}
export const getNearestPresetSize = ({ width, height }: Dim): Dim => {
  if (!width || !height) return DEFAULT_DIM

  function getDimIdx(dim: number) {
    const deltas = DIMS.map(d => Math.abs(d - dim))
    const minDelta = Math.min(...deltas)
    const idx = deltas.indexOf(minDelta)
    return idx > -1 ? idx : 0
  }

  return replaceDisabledSizes({
    width: DIMS[getDimIdx(width)],
    height: DIMS[getDimIdx(height)],
  })
}

export const isValuesGenerativeFill = (values: TaskSubmitFormValues) => {
  if ((values.controlNets ?? []).some(cn => cn.type === 'inpaint')) return true
  return false
}

export const isValuesLatentCouple = (values: TaskSubmitFormValues) => {
  return (
    Object.entries(values.latentCouple ?? {}).length > 0 ||
    (values.prompts ?? '').includes('AND')
  )
}
