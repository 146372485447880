import { CircularProgress } from '@mui/material'
import { FC } from 'react'
import { Preloader } from 'src/components/Preloader/Preloader'
import { cx } from '../utils/cx'

export const LoadingIcon = () => <CircularProgress size={28} />

const LoadingPage: FC<{
  fullscreen?: boolean
  fullHeight?: boolean
}> = ({ fullscreen, fullHeight }) => {
  return (
    <div
      className={cx(
        'w-full flex justify-center items-center',
        fullscreen ? 'h-screen' : fullHeight ? 'h-full' : 'min-h-[600px]',
      )}
    >
      <Preloader />
    </div>
  )
}

export default LoadingPage
