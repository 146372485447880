import type { CSSProperties, FC } from 'react'

export const PictureWithSources: FC<
  React.PropsWithChildren<{
    pngSrc?: string
    jpgSrc?: string
    webpSrc?: string
    svgSrc?: string
    alt?: string
    style?: CSSProperties
    className?: string
    imgClassName?: string
    width?: string | number
    height?: string | number
  }>
> = ({
  pngSrc,
  jpgSrc,
  webpSrc,
  svgSrc,
  width,
  height,
  className,
  imgClassName,
  alt,
  style,
}) => {
  if (!pngSrc && !jpgSrc) {
    return null
  }
  return (
    <picture className={className} style={style}>
      {webpSrc && <source srcSet={webpSrc} type="image/webp" />}
      {pngSrc && <source srcSet={pngSrc} type="image/png" />}
      {jpgSrc && <source srcSet={jpgSrc} type="image/jpeg" />}
      {svgSrc && <source srcSet={svgSrc} type="image/svg+xml" />}
      <img
        src={pngSrc || jpgSrc}
        alt={alt || ''}
        className={imgClassName}
        height={height}
        width={width}
      />
    </picture>
  )
}
